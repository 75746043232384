/***************************************
*
* Globals
*
***************************************/


*, ::after, ::before {
    box-sizing: inherit;
}
html, body {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
}

html{
    box-sizing: border-box;
    font-size: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    quotes: "\00AB" "\00BB" "\2039" "\203A";
    quotes: "«" "»" "‹" "›";

    &.smooth-scroll{
        scroll-behavior: smooth; }
    &.has-overlay{
        overflow-y: hidden;
    }

    body{
        /*-webkit-transform:translate3d(0,0,0); verträgt sich nicht mit position:fixed; */
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -moz-font-feature-settings: "kern"1;
        -ms-font-feature-settings: "kern"1;
        -o-font-feature-settings: "kern"1;
        -webkit-font-feature-settings: "kern"1;
        font-feature-settings: "kern"1;
        font-kerning: normal;
        font-variant-ligatures: none;

        color: @color-black;

        &.has-overlay{
            height: 100vh;
            overflow: hidden;
        }
    }
}

::selection{
	  color: @color-white;
    background-color: fade(@color-red, 85%);
    text-shadow: 0; }
::-moz-selection{
	  color: @color-white;
    background-color: fade(@color-red, 85%);
    text-shadow: none;
}


/***************************************
* Z-Index
***************************************/

/* 1. Ebene */
.toggle-menu,
.logo{
    z-index: 999999999; }
/* 2. Ebene */
#nav{
    z-index: 99999999; }
/* 3. Ebene */
div#header, footer{
    z-index: 9999999; }


/***************************************
* Elements
***************************************/

div#header, footer{
    background-color: @color-darkgrey;
    padding-left: @padding;
    padding-right: @padding;
    .screen-max-sm({
        padding-left: @padding-sm;
        padding-right: @padding-sm;
        nav{
            margin: 0 -@padding-sm;
        }
    });
}
footer{
    a, p, span{
    		color: @color-white; }
    hr{
        margin-top: 25px;
        margin-bottom: 50px;
        border-color: @color-white;
    }
}

div#header > div,
footer > div,
.container{
    max-width: @width-max;
    margin-left: auto;
    margin-right: auto;

    &.full-width{
        max-width: 100vw!important;
        & > div, & > p {
            max-width: @width-max;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.banner{
    height: ~"calc( 100vh - 110px )";
    background-size: cover;

    h1, .h1, h2, .h2, h3, .h3{
        max-width: 100%;
    }
}
.bg{
    color: @color-white;
    background-color: @color-coral;
    padding: 8px 20px 12px;
    margin-bottom: 10px;
}

div#title{
    padding-top: 75px;
    padding-bottom: 25px;
    margin-bottom: 75px;
}

section#content{
    padding-top: @logo-height + 40px;
    padding-bottom: 50px;
    background-color: @color-white;
    .screen-max-sm({
        padding-top: 80px;
    });

    .container{
        padding-left: @padding;
        padding-right: @padding;
        .screen-max-sm({
            padding-left: @padding-sm;
            padding-right: @padding-sm;
        });
    }
    article{
        margin-bottom: 50px;
    }
}
iframe{
    margin-top: 50px;
    margin-bottom: 50px;
}

div, span, section, article{
    &.bg-red, &.bg-coral, &.bg-blue,
    &.bg-lightblue, &.bg-darkgrey, &.bg-black{
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6,
        span, p, a{
            color: @color-white;
        }
    }
    &.bg-red{ background-color: @color-red; }
    &.bg-coral{ background-color: @color-coral; }
    &.bg-darkgrey{ background-color: @color-darkgrey; }
}

/* Tailwind Customization */
.container{
    margin-right: auto;
    margin-left: auto;
    & > div{
        margin: 0 10px;
    }

    &.spacing-low{
        padding: 0 -5px;
        & > div{
            margin: 0 5px;
        }
    }
}

/* Images */
img {
    display: inline-block;
    border-style: none; height: auto;
    -ms-interpolation-mode: bicubic;
    max-width: 100%;
   	font-size: 12.75px;
  	text-align: center;
  	color: rgba(10,10,10,.3);

    &.empty{
      	width: 100%;
      	padding-bottom: 30%;
    }
}

/* Spacing */
/***********/
header > .wrapper,
main > .wrapper,
footer > .wrapper{
    max-width: @width-max;
    margin: 0 auto;
}
main, .padding{
		padding: 0 @padding;
    .screen-max-sm({ padding: 0 @padding-sm; }); }
header, footer{
		padding: 20px @padding;
    .screen-max-sm({ padding: 10px @padding-sm; });
}

main{
    /*@margin: @logo-height + (2 * @padding-sm);
    .screen-min-md({ @margin: @logo-height + (2 * @padding); });
    margin-top: @margin;
    min-height: ~"calc( 100vh - (2 * @{margin}) )";*/
    margin-top: @logo-height;
}
footer{
    margin-top: @logo-height * 1.5;
}

/* Section/Article */
/*******************/

section{
    article{
        img{
            margin-top: 50px;
            margin-bottom: 50px;
        }
        .article-grid, hr{
            margin-top: 35px;
            margin-bottom: 35px;
        }

        &.article-programm{
            .article-grid, hr{
                /*margin-top: 25px;
                margin-bottom: 25px;*/
            }

            //.loopAnimationDelay(1, 10, 200ms, ~".article-grid");
            .article-grid{
                //.ani-fade-in;

                .col-text, .col-time{
                    opacity: 0;
                    animation: .5s @tween-std 0s 1 appearFromBottom forwards;
                }
                &:nth-of-type(2){ .col-text, .col-time{ animation-delay: .15s; } }
                &:nth-of-type(3){ .col-text, .col-time{ animation-delay: .3s; } }
                &:nth-of-type(4){ .col-text, .col-time{ animation-delay: .45s; } }
                &:nth-of-type(5){ .col-text, .col-time{ animation-delay: .6s; } }
                &:nth-of-type(6){ .col-text, .col-time{ animation-delay: .75s; } }
                &:nth-of-type(7){ .col-text, .col-time{ animation-delay: .8s; } }
                &:nth-of-type(8){ .col-text, .col-time{ animation-delay: .95s; } }
                &:nth-of-type(9){ .col-text, .col-time{ animation-delay: 1.1s; } }
                &:nth-of-type(10){ .col-text, .col-time{ animation-delay: 1.25s; } }

                .col-time{
                    position: relative;

                    &::before{
                        content: '\2b24';
                        color: @color-coral;
                        position: absolute;
                        left: 0; top: 0;
                        width: 20px;
                        text-align: center;
                        margin-left: 90px;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0; top: 10px;
                        height: ~"calc(100% + 45px)";
                        width: 2px;
                        background-color: @color-coral;
                        margin-left: 99px;
                    }
                    .screen-max-sm({
                        &::before{
                            margin-left: 50px; }
                        &::after{
                            margin-left: 59px;
                        }
                    });
                }
                &:last-of-type .col-time::after{
                    content: none;
                }
            }
        }
    }
}
.ani-appear{
    opacity: 0;
    animation: .5s @tween-std 0s 1 appearFromBottom forwards;
    &:nth-of-type(2){ animation-delay: .15s; }
    &:nth-of-type(3){ animation-delay: .3s; }
    &:nth-of-type(4){ animation-delay: .45s; }
    &:nth-of-type(5){ animation-delay: .6s; }
    &:nth-of-type(6){ animation-delay: .75s; }
    &:nth-of-type(7){ animation-delay: .8s; }
    &:nth-of-type(8){ animation-delay: .95s; }
    &:nth-of-type(9){ animation-delay: 1.1s; }
    &:nth-of-type(10){ animation-delay: 1.25s; }
}
@keyframes appearFromBottom {
  0% {   opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Default grid, as used by the Article field type */
.article{
    table{
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}
.article-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0rem;

    & + .article-grid {
        margin-top: 1rem;
    }
    & figure, & img {
        margin: 0;
        width: 100%;
        max-width: 100%;
    }

    .col-1 { grid-column: span 1; }
    .col-2 { grid-column: span 2; }
    .col-3 { grid-column: span 3; }
    .col-4 { grid-column: span 4; }
    .col-5 { grid-column: span 5; }
    .col-6 { grid-column: span 6; }
    .col-7 { grid-column: span 7; }
    .col-8 { grid-column: span 8; }
    .col-9 { grid-column: span 9; }
    .col-10 { grid-column: span 10; }
    .col-11 { grid-column: span 11; }
    .col-12 { grid-column: span 12; }

    div p {
        margin-top: 0;
    }

    .screen-max-ms({
        grid-template-columns: repeat(1, 1fr);

        .col-1, .col-2, .col-3, .col-4,
        .col-5, .col-6, .col-7, .col-8,
        .col-9, .col-10, .col-11, .col-12 {
            grid-column: span 1;
            margin-bottom: 1rem;
        }
    });
}

/* Base grid for "Article" powered Fields */

@media (min-width: 640px) {
    .sm\:article>p {
      margin: 1rem 0;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .sm\:article > ol {
      list-style-type: decimal;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .sm\:article > ul {
      list-style-type: disc;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .sm\:article h1 {
      font-size: clamp(1.875rem, 2.5vw, 2.25rem)
    }

    .sm\:article h2 {
      font-size: clamp(1.5rem, 2vw, 1.875rem)
    }

    .sm\:article h3 {
      font-size: clamp(1.25rem, 1.75vw, 1.5rem);
      font-weight: 700;
    }

    .sm\:article h4 {
      font-size: clamp(1.125rem, 1.5vw, 1.25rem)
    }

    .sm\:article h5 {
      font-size: 1rem;
      font-weight: 700;
    }

    .sm\:article h6 {
      font-size: 1rem;
      font-weight: 600;
    }

    .sm\:article blockquote>p {
      border-left-width: 4px;
      border-color: #b2f5ea;
      font-style: italic;
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-left: 2rem;
      font-size: clamp(1.875rem, 2.5vw, 2.25rem);
    }
}

@media (min-width: 768px) {
    .md\:article>p {
      margin: 1rem 0;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .md\:article > ol {
      list-style-type: decimal;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .md\:article > ul {
      list-style-type: disc;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .md\:article h1 {
      font-size: clamp(1.875rem, 2.5vw, 2.25rem)
    }

    .md\:article h2 {
      font-size: clamp(1.5rem, 2vw, 1.875rem)
    }

    .md\:article h3 {
      font-size: clamp(1.25rem, 1.75vw, 1.5rem);
      font-weight: 700;
    }

    .md\:article h4 {
      font-size: clamp(1.125rem, 1.5vw, 1.25rem)
    }

    .md\:article h5 {
      font-size: 1rem;
      font-weight: 700;
    }

    .md\:article h6 {
      font-size: 1rem;
      font-weight: 600;
    }

    .md\:article blockquote>p {
      border-left-width: 4px;
      border-color: #b2f5ea;
      font-style: italic;
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-left: 2rem;
      font-size: clamp(1.875rem, 2.5vw, 2.25rem);
    }
}

@media (min-width: 1024px) {
    .lg\:article>p {
      margin: 1rem 0;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .lg\:article > ol {
      list-style-type: decimal;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .lg\:article > ul {
      list-style-type: disc;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .lg\:article h1 {
      font-size: clamp(1.875rem, 2.5vw, 2.25rem)
    }

    .lg\:article h2 {
      font-size: clamp(1.5rem, 2vw, 1.875rem)
    }

    .lg\:article h3 {
      font-size: clamp(1.25rem, 1.75vw, 1.5rem);
      font-weight: 700;
    }

    .lg\:article h4 {
      font-size: clamp(1.125rem, 1.5vw, 1.25rem)
    }

    .lg\:article h5 {
      font-size: 1rem;
      font-weight: 700;
    }

    .lg\:article h6 {
      font-size: 1rem;
      font-weight: 600;
    }

    .lg\:article blockquote>p {
      border-left-width: 4px;
      border-color: #b2f5ea;
      font-style: italic;
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-left: 2rem;
      font-size: clamp(1.875rem, 2.5vw, 2.25rem);
    }
}

@media (min-width: 1280px) {
    .xl\:article>p {
      margin: 1rem 0;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .xl\:article > ol {
      list-style-type: decimal;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .xl\:article > ul {
      list-style-type: disc;
      list-style-position: inside;
      font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    }

    .xl\:article h1 {
      font-size: clamp(1.875rem, 2.5vw, 2.25rem)
    }

    .xl\:article h2 {
      font-size: clamp(1.5rem, 2vw, 1.875rem)
    }

    .xl\:article h3 {
      font-size: clamp(1.25rem, 1.75vw, 1.5rem);
      font-weight: 700;
    }

    .xl\:article h4 {
      font-size: clamp(1.125rem, 1.5vw, 1.25rem)
    }

    .xl\:article h5 {
      font-size: 1rem;
      font-weight: 700;
    }

    .xl\:article h6 {
      font-size: 1rem;
      font-weight: 600;
    }

    .xl\:article blockquote>p {
      border-left-width: 4px;
      border-color: #b2f5ea;
      font-style: italic;
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-left: 2rem;
      font-size: clamp(1.875rem, 2.5vw, 2.25rem);
    }
}


/* Grid */
/********/
.grid{
    &.grid-masonry{
        grid-template-rows: masonry;
    }
    &.grid-listing{
        .item{
            &.item-bg{
                padding: 10px 20px;
                background-color: @color-lightgrey;

                h1, .h1, h2, .h2, h3, .h3,
                h4, .h4, h5, .h5, h6, .h6,
                p, a{
                    //color: @color-white;
                }
            }

            .item-image img{
                width: 100%;
            }
        }
    }

    // Grid Lines
    &[class*="grid-cols-2"]{
        .item{
            position: relative;
            .border-bottom;
            padding-bottom: 25px;
            margin-bottom: 25px;
            &:nth-last-of-type(1){
                border-bottom: 0;
            }

            .screen-min-sm({
                &:nth-last-of-type(2){
                    border-bottom: 0;
                }
                &:nth-child(odd){
                    margin-right: 25px;
                    &::after{
                        .border-right;
                        content: ' ';
                        position: absolute;
                        right: -25px; top: 0;
                        height: 100%;
                    }
                }
                &:nth-child(even){
                    margin-left: 25px;
                }
            });
        }
    }
}

.pagination{
    margin-top: 50px;
}


/* Lists/Entries */
/*****************/
.container-fluid.list-container{
  	margin-top: 0;
  	margin-bottom: 0;
}
.list-header,
.container-fluid.list-header{
    margin-bottom: 80px;
}

.entry{
    //margin-bottom: 40px;
    animation: fadeIn .5s cubic-bezier(.5,0,0,.5) forwards;

    & > div{
    	  cursor: pointer;
    }
    .entry-title{
        margin-top: 5px;
    }
    .entry-link{
        display: inline-block;
        float: right;
        margin-top: 5px;
    }
    .entry-image{
        margin-bottom: 8px;
    }
    /*.screen-max-sm({
        margin-bottom: 15px;
    });
    .screen-max-xs({
        margin-bottom: 5px;
    });*/
}

/* Pages */
/*********/

.index-map{
    &, .container, .container > div{
        min-height: 500px;
        width: 100vw;
        max-width: 100vw;
        margin-left: 0;
        margin-right: 0;
    }
    iframe{
        width: 100vw;
        margin: 0;
    }
}

/* Detail */
/**********/

.artikeldetail{
    max-width: 750px;
    margin: 0 auto;
}

.detail-title{
    margin-bottom: 25px;
}
.detail-teaser{
    margin-bottom: 25px;
}
.detail-image{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}
.persondetail .detail-image{
    .screen-min-sm({
        margin-top: 0;
    });
}

/* Personen */
/************/

.home, .informationen{
    .person-container{
        text-align: center;
    }
    .entry-person{
        display: inline-block;
        width: 250px;
        max-width: 50%;
        vertical-align: top;
        margin-bottom: 35px;

        .entry-title,
        .entry-image{
            margin: 0;
        }
        img{
            width: 150px;
            height: 150px;
          	border-radius: 9999px;
        }
        p{
            margin-bottom: 0;
        }

        /*&:nth-of-type(2)::after,
        &:nth-of-type(5)::after{
            content: "\A";
            white-space: pre;
        }*/
    }
}

.entry-person-detail {
    border-radius: 9999px !important;
}


/***************************************
* Lazy/Progressive Loading
***************************************/

/* Z-Index */
.progressive-wrapper{
	  z-index: 1;
}
.progressive,
.video-container{
	  z-index: 10;
}
.fancy-hover.loaded .entry-limit-color,
.fancy-hover.fancy-load-independent .entry-limit-color{
    &:before, &:after{
        z-index: 99;
    }
}
.entry .progressive{
    &:before, &:after{
        z-index: 100;
    }
}

/* Height */
.progressive,
.video-container.enforce-aspect-ratio{
    .lazy-aspect-ratio{
        padding-bottom: 100%!important;
    }
}
.list-similar{
    .progressive,
    .video-container.enforce-aspect-ratio{
        .lazy-aspect-ratio{
            padding-bottom: 70%!important;
        }
    }
}

/* Actual Progressive */
.progressive {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none;
    &:not(.transparent){
        background-color: @color-black;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none;
        border: 0 none;
      	background-color: transparent;
    }
    .aspect-ratio,
    .lazy-aspect-ratio{
        padding-bottom: 75%;
        z-index: -999999;
    }

    &.enforce-aspect-ratio{
        img{
            position: absolute;
            top: 0; left: 0;
        }
    }
    &:not(.enforce-aspect-ratio){
        img{
            position: relative;
            &.reveal{
                position: absolute;
            }
        }
        .lazy-aspect-ratio{
            display: none;
            padding: 0;
        }
    }
}

.entry{
    .entry-limit-color{
        position: relative;

        a{
            position: relative;
            display: block;
        }
    }

    .progressive{
        &:before, &:after{
            content: ' ';
            position: absolute;
            transition: all 1s @tween-std;
        }
        &:after{
            top: 50%; left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: all @speed-std @tween-std; }
        &:before{
            top: 0; left: 0;
            width: 100%; height: 100%;
            background-color: @color-black;
        }

        &.loaded:before, &.loaded:after{
            opacity: 0;
        }
    }
}

/* Fancy Hover */
.fancy-hover.loaded,
.fancy-hover.fancy-load-independent{
    .entry-limit-color{
        &:before, &:after{
          	content: '';
          	position: absolute;
            top: 0; left: 0;
          	width: 100%; height: 100%;
            pointer-events: none;
            background-color: transparent;
            //transform: translate3d(0,0,0);
          	//opacity: 0;
        }
        &:after{
            //background-color: fade(@color-red, 25%);
            .screen-min-sm({
                .border;
                border-width: 0;
                border-color: @color-red;
                transition: border @speed-fast @tween-custom;
            });
        }
        /*.progressive{
            transition: background-color @speed-fast;
        }*/
    }
    /*************************/
    /* NACH UNTEN VERSCHOBEN */
    /*************************/
    /*.screen-min-sm({
        &.entry-limit, .entry-limit{
            &:hover .entry-limit-color{
                //transform: scale(1.02);
                //.progressive{ background-color: @color-red; }
                //&:before, &:after{ opacity: 1; }
                &:after{
                    //opacity: 1;
                    border-width: @width-scrollbar;
                }
            }
        }
    });*/
}
.screen-min-sm({
    .fancy-hover.loaded.entry-limit,
    .fancy-hover.loaded .entry-limit,
    .fancy-hover.fancy-load-independent.entry-limit,
    .fancy-hover.fancy-load-independent .entry-limit{
        &:hover .entry-limit-color{
            &:after{ border-width: 5px; }
        }
    }
    .entry-limit:hover .fancy-hover.loaded,
    .entry-limit:hover .fancy-hover.fancy-load-independent{
        .entry-limit-color{
            &:after{ border-width: 5px; }
        }
    }
});

hr{
  	margin: 10px 0;
  	border: 0;
  	border-top: @border-std; }
.entry hr{
  	width: 50px;
  	margin: 0; }
.search .entry hr{
	  margin-bottom: 10px;
}


/***************************************
* Forms
***************************************/

button, .button, input, optgroup, select, textarea {
    display: block;
    font-family: @font-title;
    font-weight: bold;
    line-height: 1.5;
  	outline: 0!important;
  	-webkit-appearance: none!important;
    border-radius: 0;
}
section{
    button, .button{
        display: inline-block;
        //float: left;
        cursor: pointer;
      	padding: 8px 18px 9px;
      	color: @color-white;
        background-color: @color-red;
        transition: background-color @speed-std @tween-std;
        border: 0;
        border-radius: 4px;

        &[type=submit]{
            float: right;
        }
        &:hover{
            cursor: pointer;
            background-color: @color-coral;
            border-color: @color-coral;
        }

        &.btn-search{
            &, &.active, &:active{
              	width: 30px;
              	padding-top: 8px;
            }
        }
    }

    form{
        width: 100%;
        margin-bottom: 25px;

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
            color: fade(@color-white, 50%); }
        input::-moz-placeholder,
        textarea::-moz-placeholder {
            color: fade(@color-white, 50%); }
        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
            color: fade(@color-white, 50%); }
        input::placeholder,
        textarea::placeholder {
            color: fade(@color-white, 50%);
        }

        [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local],
        [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel],
        [type=time], [type=url], [type=color], textarea, select, input:not([type=radio]):not([type=checkbox]) {
            box-sizing: border-box;
            box-shadow: none;
            height: 50px;
            width: 100%;
            padding: 9px 15px 12px;
            margin-bottom: 15px;
            background-color: @color-lightgrey;

            &:focus {
              	outline: 0;
              	color: @color-red;
                background-color: @color-white;
              	//border-width: @stroke-width-std;
              	border-color: @color-red;
            }
            &.error{
                color: @color-error;
                border-width: @stroke-width-std;
                //border-color: @color-error;
                border-color: @color-white;
                border-style: dashed;
            }
            .screen-max-sm({
                margin-bottom: 15px;
            });
        }
        [type=radio]{
            -webkit-appearance: radio!important;

            &, & + label{
                display: inline-block;
            }
        }
        label{
            display: block;
            margin-bottom: 5px;
        }
        textarea{
            overflow: auto;
            min-height: 200px;
            max-width: 100%;

            &[rows] {
            	  height: auto;
            }
        }
        input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
            &, &:hover, &:focus {
                border-color: @color-autofill;
                -webkit-text-fill-color: @color-autofill;
                -webkit-box-shadow: 0 0 0px 1000px @color-grey inset;
                transition: background-color 5000s ease-in-out 0s;
            }
        }

        .form-thanks {
            display: none;
            margin-bottom: 80px;
        }
        .attention{
            position: absolute;
            top: 11px;
            right: 21px;
            pointer-events: none;
            display: none;

            &.error{
                display:inline-block;
            }

            path{
                fill: @color-white;
            }
        }

        &.read-only{
            input, textarea, label{
                color: @color-red!important;
                pointer-events: none;
            }
            input, textarea{
                border-color: fade(@color-red, 50%)!important;
            }
        }
    }

}

/***************************************
* Icons
***************************************/

.icon-bar {
    display: block;
    height: @stroke-width-icons;
    width: 100%!important;
    position: relative;
    background-color: @color-black;
    transition: all @speed-fast @tween-std, background-color .01s, opacity @speed-fast;
    border-radius: 0!important;
}

a, .entry-limit a{
		&:hover{
				.link-back, .link-next,
				&.link-back, &.link-next{
						.icon{
								width: 25px;
						}
				}
		}
}
a{
    &.link-back,
    &.link-next{
        display: inline-block;

        span{
            float: left;
            margin-right: 8px;

            &:last-of-type{
                padding-top: 1px;
            }
        }
        .icon{
            display: block;
            position: relative;
            width: 17px;
            height: 10px;
            transition: width @speed-fast @tween-std;

            .icon-bar{
                transform-origin: left;

                &:nth-of-type(1){
                    width: 8px!important;
                    left: 0px; top: 7px;
                    transform: rotate(-50deg); }
                &:nth-of-type(3){
                    width: 8px!important;
                    left: 0px; top: 5px;
                    transform: rotate(50deg); }
                &:nth-of-type(2){
                    width: 100%;
                    top: 6px;
                }
            }
        }
    }
    &.link-next{
        span{
            margin-right: 0;
        }
        .icon{
            .icon-bar{
                float: right;
                transform-origin: right;

                &:nth-of-type(1){
                    right: 0px; }
                &:nth-of-type(3){
                    right: 0px;
                }
            }
        }
    }

    &.link-external{
        .icon{
            display: inline-block;
            line-height: 10px;
            position: relative;
            width: 10px;
            height: 10px;
            margin-right: 2px;
            margin-left: 2px;
            transition: width @speed-fast @tween-std, height @speed-fast @tween-std;
            transition-delay: @speed-std;

            .icon-bar{
                position: absolute;
                top: 0; right: 0;
                transform-origin: right;

                &:nth-of-type(1){
                    width: @stroke-width-std!important;
                    height: 8px; }
                &:nth-of-type(2){
                    width: 8px!important;
                    height: @stroke-width-std;  }
                &:nth-of-type(3){
                    width: 13px!important;
                    transform: rotate(-45deg);
                    transition-delay: @speed-std;
                }
            }
        }
    }
}


/***************************************
* SVG
***************************************/

svg{
    .solid, .st0{
        stroke: none;
        //fill: @color-black;
    }
    .red{
        fill: @color-red;
    }
    .line, .dotted{
        fill: none;
        stroke: inherit;
        stroke-width: @stroke-width-std; /* 2px */
        stroke-miterlimit: 0;
        vector-effect: non-scaling-stroke; }
    .dotted{
        stroke-linecap: round;
        stroke-dasharray: .25,3.5;
    }
    &.inverted{
        path{
            fill: @color-white;
        }
    }
}
