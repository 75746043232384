/***************************************
*
* Mixins
*
***************************************/


/* Desktop/Mobile */
.screen-min-desktop(@rules) { @media (min-width: 768px) { @rules(); } }
.screen-max-mobile(@rules) {  @media (max-width: 767px) { @rules(); } }

/* Full Width */
@query-min: ~"(min-width: @{width-max})";
.screen-min-fullwidth(@rules) { @media @query-min { @rules(); } }

/* LG */
.screen-min-lg(@rules) { @media (min-width: 1200px) { @rules(); } }
.screen-max-lg(@rules) { @media (max-width: 1199px) { @rules(); } }
.screen-lg(@rules) { @media (min-width: 1200px) and (max-width: 1399px) { @rules(); } }

/* MD */
.screen-min-md(@rules) { @media (min-width: 992px) { @rules(); } }
.screen-max-md(@rules) { @media (max-width: 991px) { @rules(); } }
.screen-md(@rules) { @media (min-width: 992px) and (max-width: 1199px) { @rules(); } }

/* SM */
.screen-min-sm(@rules) { @media (min-width: 768px) { @rules(); } }
.screen-max-sm(@rules) { @media (max-width: 767px) { @rules(); } }
.screen-sm(@rules) { @media (min-width: 768px) and (max-width: 991px) { @rules(); } }

/* MS */
.screen-min-ms(@rules) { @media (min-width: 480px) { @rules(); } }
.screen-max-ms(@rules) { @media (max-width: 479px) { @rules(); } }
.screen-ms(@rules) { @media (min-width: 480px) and (max-width: 767px) { @rules(); } }

/* XS */
.screen-max-xs(@rules) { @media (max-width: 379px) { @rules(); } }
.screen-xs(@rules) { @media (min-width: 379px) and (max-width: 479px) { @rules(); } }

/* Height XS */
.screen-min-height-xs(@rules) { @media (min-height: 380px) { @rules(); } }
.screen-max-height-xs(@rules) { @media (max-height: 379px) { @rules(); } }

/* Height MS */
.screen-min-height-ms(@rules) { @media (min-height: 521px) { @rules(); } }
.screen-max-height-ms(@rules) { @media (max-height: 520px) { @rules(); } }


/*.screen-tablet-landscape(@rules) {
    @media (max-width: 1024px) { @rules(); }
}
.screen-tablet(@rules) {
    @media (max-width: 979px) { @rules(); }
}
.screen-mobile-landscape(@rules) {
    @media (max-width: 992px) and (max-height: 400px) and (orientation: landscape) { @rules(); }
}
.screen-mobile(@rules) {
    @media (max-width: 414px) { @rules(); }
}*/

/*@media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: landscape) {

}*/

.ie11(@rules) {
    @media all and (-ms-high-contrast: none) { @rules(); }
}
.ie(@rules) {
    @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) { @rules(); }
}
