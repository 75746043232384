/***************************************
*
* Fonts
*
***************************************/


@font-face {
		font-weight: normal; font-style: normal; font-family: 'Maax';
	  src:  url('../fonts/maax-rounded_medium.eot?') format('eot'),
	        url('../fonts/maax-rounded_medium.woff') format('woff'),
	        url('../fonts/maax-rounded_medium.ttf') format('truetype'); }
@font-face {
		font-weight: normal; font-style: italic; font-family: 'Maax';
	  src:  url('../fonts/maax-rounded_medium-italic.eot?') format('eot'),
	        url('../fonts/maax-rounded_medium-italic.woff') format('woff'),
	        url('../fonts/maax-rounded_medium-italic.ttf') format('truetype');
}
