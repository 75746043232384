/***************************************
*
* Menue
*
***************************************/

div#header{
    padding-top: 20px;
    padding-bottom: 20px;
    .screen-max-sm({
        padding-top: 15px;
        padding-bottom: 15px;
    });

    .logo{
        .full svg{
            max-height: @logo-height;
            .screen-max-sm({
                max-height: @logo-height - 10px;
            });
        }
        .short{ display: none; }
    }
		#progress{
        position: absolute;
        bottom: 0;
				left: 0;
        width: 100vw;
        height: 3px;
    }

    .screen-max-sm({
				// toggle menu
				button#nav-toggle{
		        background-color: @color-white;
		        margin: -15px -@padding-sm;
		        height: @logo-height - 10px + 30px;
		        width: @logo-height - 10px + 30px;
		        svg{
		            margin: 15px 30px;
		            fill: @color-black;
		        }
		    }
		});

    nav{
    		padding: 0;
    		margin: 0;
        list-style: none;

    		.screen-max-sm({
            margin: 40px -20px 0;
            padding: 10px 20px;
    				background-color: @color-darkgrey;

    				a{
                display: block;
                font-size: 35px;
                line-height: .9;
    						padding: 5px 0 @padding-sm 0;
                text-align: center;

    						border-bottom: solid @stroke-width-std fade(@color-black, 15%);
    						&:last-of-type{
    								border: none;
    						}
    				}

    				/*position: fixed;
    				top: 0; left: 0;
    				width: 100vw;
    				height: 100vh;*/
        });

    		.screen-min-sm({
            position: relative;
            height: @logo-height;
            overflow-x: hidden;
            margin-left: 25px;

            ul{
                float: right;
                white-space: nowrap;

                li, div{
                    display: inline-block;
                    vertical-align: middle;
                }
                li{
                    line-height: @logo-height;
                    a{
                        display: inline-block;
                        line-height: 18px;
                        word-break: break-word;
                        vertical-align: middle;
                        text-align: center;
                        &.active{
                						color: @color-coral;
                				}
                    }
                }
            }
        });
    }

    .screen-min-sm({
        &.header-up{
            padding-top: 15px;
            padding-bottom: 15px;

            .logo{
                .short{ display: block; }
                .full{ display: none; }
            }

            nav{
                height: @logo-height-sm + 30px;
                margin-top: -15px;
                margin-bottom: -15px;
                li{
                    height: @logo-height-sm + 30px;
                    line-height: @logo-height-sm + 30px;
                    a{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
		});

    .search{
        .screen-min-sm({
            .search-form{
                input, button{
                    width: @logo-height-sm + 30px;
                    height: @logo-height-sm + 30px!important;
                }
                input{
                    padding: 5px 0 7px;
                    margin-right: -@logo-height-sm - 30px;
                    opacity: 0;
                    transition: width @tween-custom @speed-std,
                                margin-right @tween-custom @speed-std,
                                padding @tween-custom @speed-std;
                    &:focus,
                    &:not([value=""]){
                        width: 250px;
                        opacity: 1;
                        margin-left: 10px;
                        margin-right: 0;
                        padding: 5px 20px 7px;
                        transition: width @tween-custom @speed-std,
                                    margin-right @tween-custom @speed-std,
                                    padding @tween-custom @speed-std,
                                    opacity @speed-slow @speed-std;

                        & + button{
                            background-color: @color-white;
                            svg{ fill: black; }
                        }
                    }
                }
                button{
                    transition: width @tween-custom @speed-std,
                                padding @tween-custom @speed-std,
                                background-color @tween-custom @speed-std;
                    svg{ transition: fill @tween-custom @speed-std; }
                }
            }
        });
    }
}

.search{
    .search-form{
        display: flex;
        overflow: hidden;

        input, button{
            flex: initial;
            height: @logo-height-sm + 30px!important; }
        input{
            background-color: @color-darkgrey-lighter; }
        button{
            background-color: @color-darkgrey;
            width: @logo-height-sm + 30px;
            border-radius: 0;
            svg{ margin: 0; }
        }
    }

    .screen-min-sm({
        margin-left: 0;

        .search-form{
            height: 100%;
            input{
                padding: 5px 20px 7px;
            }
            button{
                padding: 0 15px;
            }
            &:hover button,
            button:hover{
                background-color: @color-darkgrey;
                svg{ fill: white; }
            }
        }
    });

    .screen-max-sm({
        margin-top: 15px;

        .search-form{
            input, button{
                height: 70px;
            }
            input{
                width: 100%;
                padding: 6px 25px;
                text-align: left;
            }
            button{
                width: 80px;
                padding: 0 20px;
                background-color: @color-white;
                svg{
                    fill: black;
                    width: 100%;
                }
            }
        }
    });
}

footer{
    .logo.logo-short svg{
        max-height: 26px;
        .screen-max-sm({
            max-height: 20.5px;
        });
    }
}


/* Menu-Bar Toggle */
/*********/
.toggle-menu,
.toggle-menu:hover {
    position: fixed;
    top: 0; right: 0;
    height: 95px;
    width: 100px;
    padding: @padding;
    padding-top: @padding;
    background-color: transparent!important;
    box-shadow: none!important;
    outline: none;
    border: 0;

    .icon-bar{
        background-color: @color-red;
				width: 100%;
				left: 0;
				&:nth-of-type(1){ top: -6px; }
        &:nth-of-type(3){ top: 6px; }
        &:nth-of-type(2){ }
    }
    &.toggled{//&[aria-expanded="true"]{
        margin-right: 5px;
        .icon-bar{
            width: 100%!important;
						left: 4px;
            &:nth-of-type(1){ top: 2px; transform: rotate(45deg); }
            &:nth-of-type(3){ top: -2px; transform: rotate(-45deg); }
            &:nth-of-type(2){ opacity: 0; }
        }
    }

    .screen-min-sm({
        display: none;
    });
}
