/***************************************
*
* Utils
*
***************************************/


html.no-transition * {
    transition: none !important;
}

.overlay-only{
    display: none; }
.has-overlay .overlay-only{
    display: block;
}


/* Margin/Padding */
/******************/

.mt-0-a{  margin-top: 0!important; }
.mb-0-a{  margin-bottom: 0!important; }
.mx-0-a{ margin: 0!important; }

@media screen and (max-width: 479px) {
	.xs-no-margin{ margin: 0!important; }
	.xs-no-margin-bottom{ margin-bottom: 0!important; }
	.hidden-xs{ display: none; }
}

@media screen and (min-width: 768px) {
	  .padding-top-50{ padding-top: 50px!important; }
}

@media screen and (min-width: 992px) {
  	.padding-right-md-10{ padding-right: 10px!important; }
  	.padding-right-md-25{ padding-right: 25px!important; }
  	.padding-right-md-50{ padding-right: 50px!important; }
  	.padding-right-md-75{ padding-right: 75px!important; }
}
.no-padding{ padding:0!important; }

.large-margins{
  	margin-top: 75px!important;
  	margin-bottom: 75px!important;
    .screen-max-md({
        margin-top: 50px!important;
        margin-bottom: 50px!important;
    });
    .screen-max-sm({
        margin-top: 25px!important;
        margin-bottom: 25px!important;
    });
}

/* Columns */
/***********/

.column-4, .column-3, .column-2{
    column-gap: 30px;
}
.column-4{ columns: 4; }
.column-3{ columns: 3; }
.column-2{ columns: 2; }

.screen-max-sm({
    .column-2, .column-3, .column-4{ columns: 1; }
});
.screen-max-md({
    .column-3, .column-4{ columns: 2; }
});
/*.screen-min-md({
    .spacing-40{
        .column-4, .column-3, .column-2{ column-gap: 40px; } }
    .spacing-60{
        .column-4, .column-3, .column-2{ column-gap: 60px; } }
    .spacing-80{
        .column-4, .column-3, .column-2{ column-gap: 80px; } }
    .spacing-100{
        .column-4, .column-3, .column-2{ column-gap: 100px; }
    }
});
.screen-min-sm({
    .spacing-40{
        .column-4, .column-3, .column-2{ column-gap: 30px; } }
    .spacing-60{
        .column-4, .column-3, .column-2{ column-gap: 40px; } }
    .spacing-80{
        .column-4, .column-3, .column-2{ column-gap: 50px; } }
    .spacing-100{
        .column-4, .column-3, .column-2{ column-gap: 60px; }
    }
});*/


/* Width */
/*********/

.width-33{
    float: left;
    width: 33%;
}
.width-50{
    float: left;
    width: 50%;
}
.width-66{
    float: left;
    width: 66%;
}


/* Full Width */
/**************/

/*.full-width{
    overflow: hidden;
}
.screen-max-sm({
    .full-width{               margin-left:  -@padding-sm!important;
                               margin-right: -@padding-sm!important; }
    .full-width-left{          margin-left:  -@padding-sm!important; }
    .full-width-right{         margin-right: -@padding-sm!important; }
    .full-width-padding-left{  padding-left:  @padding-sm!important; }
    .full-width-padding-right{ padding-right: @padding-sm!important; }
});
.screen-min-md({
    .full-width{               margin-left:  -@padding!important;
                               margin-right: -@padding!important; }
    .full-width-left{          margin-left:  -@padding!important; }
    .full-width-right{         margin-right: -@padding!important; }
    .full-width-padding-left{  padding-left:  @padding!important; }
    .full-width-padding-right{ padding-right: @padding!important; }
});
.screen-min-fullwidth({
    @width-calculated: (@width-max - (2 * @padding)) / 2;
    .full-width{               margin-left:   ~"calc( @{width-calculated} - 50vw)"!important;
                               margin-right:  ~"calc( @{width-calculated} - 50vw)"!important; }
    .full-width-left{          margin-left:   ~"calc( @{width-calculated} - 50vw)"!important; }
    .full-width-right{         margin-right:  ~"calc( @{width-calculated} - 50vw)"!important; }
    .full-width-padding-left{  padding-left:  ~"calc(-@{width-calculated} + 50vw)"!important; }
    .full-width-padding-right{ padding-right: ~"calc(-@{width-calculated} + 50vw)"!important; }
});*/


/* Fixed */
/*********/

.is-static{   position: static!important; }
.is-fixed{    position: fixed!important; }
.is-absolute{ position: absolute!important; }
.is-absolute-bottom{
	  position: absolute!important;
  	bottom: 0!important;
  	top: auto!important; }
@media screen and (max-width: 479px) {  .xs-fixed{ position: fixed; } }
@media screen and (min-width: 480px) {  .ms-fixed{ position: fixed; } }
@media screen and (min-width: 768px) {  .sm-fixed{ position: fixed; } }
@media screen and (min-width: 992px) {  .md-fixed{ position: fixed; } }
@media screen and (min-width: 1200px) { .lg-fixed{ position: fixed; } }

/* Sticky on Scroll */
/********************/

.screen-min-sm({
    .is-sticky-parent{

    }
    .is-sticky{
        display: inline-block;
        position: relative;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        margin: 0 0 10px 0;
        margin-top: -60px;
        padding-top: 60px;
    }
    .height-100pct{
        height: 100%;
    }
});


/* Borders */
/***********/

.no-border{      border: 0!important; }
.border{         border: @border-std; border-color: inherit; }
.border-top{     border-top: @border-std; border-color: inherit; }
.border-bottom{  border-bottom: @border-std; border-color: inherit; }
.border-right{   border-right: @border-std; border-color: inherit; }
.border-left{    border-left: @border-std; border-color: inherit; }


/* Various */
/***********/

.nobreak{
    white-space: nowrap;
}
