/***************************************
*
* Typography
*
***************************************/

body, #app, button, input, select, textarea {
    font-family: @default-font;
    font-weight: 400;
    line-height: 1.35;
    //letter-spacing: -.25px;
}
body, #app, button {
  	font-size: @font-size;
    .screen-max-sm({
        font-size: @font-size-mob;
    });
}

blockquote, dd, div, dl, dt, form,
h1, h2, h3, h4, h5, h6,
li, ol, p, pre, td, th, ul {
	  margin: 0; padding: 0; }
a, b, em, i, small, strong {
	  line-height: inherit; }
dl, ol, p, ul {
    margin-bottom: 10px;
}


/* Titles */
/**********/

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6{
		font-family: @font-title;
    color: @color-red;
    font-weight: bold;
    /*color: var(--c-text-gradient-article-from);
    background-image: linear-gradient(90deg,var(--c-text-gradient-article-from),var(--c-text-gradient-article-to));*/

    &.medium{
        font-weight: 500;
    }
}
footer{
    h1, .h1, h2, .h2, h3, .h3,
    h4, .h4, h5, .h5, h6, .h6{
        //border-top: @stroke-width-std solid @color-white;
        color: @color-white;
    }
}
h1, .h1 {
	  font-size: 38px;
		line-height: .95;
	  letter-spacing: -.5px;
    max-width: 75%;
	  margin-bottom: 25px;
    margin-right: auto;
    .screen-max-sm({
        font-size: 30px;
        line-height: 1.05;
    	  letter-spacing: -.25px;
        max-width: 100%;
    });
}
h2, .h2 {
	  font-size: 28px;
    line-height: 1.1;
    letter-spacing: -.25px;
	  margin-bottom: 20px;
    .screen-max-sm({
        font-size: 20px;
    });
}
h3, .h3 {
	  font-size: 22px;
    line-height: 1.3;
    letter-spacing: -.25px;
	  margin-bottom: 15px;
    .screen-max-sm({
        font-size: 16px;
    });
}


/* Menu */
/********/

div#header nav{
    a, input, button{
        font-family: @font-title;
        color: @color-white;

        .screen-max-sm({
            font-size: 20px;
            line-height: 1.2;
            text-align: right;
        });
    }
    a{
        font-weight: bold;
    }
    .screen-min-sm({
        margin: 0 -10px;
        li{
            padding: 0 10px;
            a{
                font-size: 16px;
            }
        }
    });
}
footer li a{
    display: inline-block;
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 5px;
}


/* General */
/***********/

p{
    line-height: 1.35;

    &.text-large{
        font-style: @font-title;
        font-size: 22px;
        line-height: 1.15;
        letter-spacing: 0;
        color: fade(@color-black, 65%);

        max-width: 600px;
        margin-right: auto;

        .screen-max-sm({
            max-width: 100%;
        });
    }
}

.meta, figcaption, .link-edit{
    font-family: @font-title;
    font-size: @font-size-meta;
    font-weight: 500;
    letter-spacing:.5px;
    text-transform: uppercase;
}
a.meta, .link-edit{
    //font-weight: bold;
}
figcaption{
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}

mark{
    color: white;
    background-color: @color-coral;
    padding: 0 2px;
    border-radius: 2px;
}

/* Links */
/*********/

p a{
    color: @color-red;
}
a{
    text-decoration: none;

    &:hover{
        cursor: pointer;
    }
    &.nolink{
        cursor: default;
    }
}


/* Copy */
/***********/

ol {
  padding: 20px;
  list-style-type: decimal;
}
ol li {
  margin: 0 10px;
  padding: 0 10px;
}

/* Lists */
/*********/

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        padding: 0;
        margin: 0;
    }
}

/* Change List Bullet Icon*/
main ul:not(.custom-styled){
    list-style-type: none!important;
    text-indent: -20px;
    padding-left: 18px;
    margin-top: 12px;
    margin-bottom: 12px;

    & > li{
        margin-bottom: 2px;

        &::before{
            content: "\2014\a0\a0";
            color: @color-red;
        }
        li{
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
}

ol {
	  list-style-type: none;
	  counter-reset: item;
	  margin: 0 0 25px 0;
	  padding: 0;

		li {
			  display: table;
			  counter-increment: item;
			  margin-bottom: 5px;

				&:before {
				    content: counters(item, ".") ". ";
				    display: table-cell;
				    padding-right: 10px;
				}
		}
}
