/***************************************
*
* Imports
*
***************************************/
/***************************************
*
* Variables
*
***************************************/
/*
    - web safe colors
    https://www.rapidtables.com/web/color/Web_Safe.html#:~:text=Web%20safe%20colors%20are%20formed,palette%20at%20the%20same%20time.
*/
/* Color */
/*********/
/* Sizes/Distances */
/*******************/
/* Font */
/********/
/* Border */
/**********/
/* Ani */
/*******/
/***************************************
*
* Mixins
*
***************************************/
/* Desktop/Mobile */
/* Full Width */
/* LG */
/* MD */
/* SM */
/* MS */
/* XS */
/* Height XS */
/* Height MS */
/*.screen-tablet-landscape(@rules) {
    @media (max-width: 1024px) { @rules(); }
}
.screen-tablet(@rules) {
    @media (max-width: 979px) { @rules(); }
}
.screen-mobile-landscape(@rules) {
    @media (max-width: 992px) and (max-height: 400px) and (orientation: landscape) { @rules(); }
}
.screen-mobile(@rules) {
    @media (max-width: 414px) { @rules(); }
}*/
/*@media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: landscape) {

}*/
@import "tailwind.css";
/***************************************
*
* Fonts
*
***************************************/
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'Maax';
  src: url('../fonts/maax-rounded_medium.eot?') format('eot'), url('../fonts/maax-rounded_medium.woff') format('woff'), url('../fonts/maax-rounded_medium.ttf') format('truetype');
}
@font-face {
  font-weight: normal;
  font-style: italic;
  font-family: 'Maax';
  src: url('../fonts/maax-rounded_medium-italic.eot?') format('eot'), url('../fonts/maax-rounded_medium-italic.woff') format('woff'), url('../fonts/maax-rounded_medium-italic.ttf') format('truetype');
}
/***************************************
*
* Animations
*
***************************************/
body nav a {
  transition: color 0.125s ease-in-out;
}
body .branding .logo svg .solid {
  transition: fill 0.125s ease-in-out;
}
/* Keyframe Animations */
/***********************/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ani-fade-in {
  animation: fadeIn 0.25s ease-in-out forwards;
  opacity: 1;
}
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ani-fade-in {
    opacity: 1;
    -ms-animation: none;
  }
}
.ani-fade-out {
  animation: fadeOut 0.25s ease-in-out forwards;
  opacity: 0;
}
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ani-fade-out {
    opacity: 1;
    -ms-animation: none;
  }
}
/****/
@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
}
.ani-shake {
  opacity: 1;
  animation: shake 0.5s ease-in-out forwards;
}
/* Loops */
/*********/
/****/
/* Animation Helpers */
/*********************/
.js-letter-by-letter {
  transition: height 0.2s ease-in-out;
}
.js-scroll-appear {
  opacity: 0;
  top: 0;
  transition: opacity 0.5s ease-in-out, top 0.75s cubic-bezier(0, 0.37, 0.1, 0.95);
}
.js-scroll-appear .in-view {
  opacity: 1;
  transition-delay: 0.5s;
}
@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .js-scroll-appear .in-view {
    opacity: 1;
    -ms-animation: none;
  }
}
/***************************************
*
* Typography
*
***************************************/
body,
#app,
button,
input,
select,
textarea {
  font-family: 'Helvetica Neue', 'Tahoma', sans-serif;
  font-weight: 400;
  line-height: 1.35;
}
body,
#app,
button {
  font-size: 16px;
}
@media (max-width: 767px) {
  body,
  #app,
  button {
    font-size: 15px;
  }
}
blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0;
}
a,
b,
em,
i,
small,
strong {
  line-height: inherit;
}
dl,
ol,
p,
ul {
  margin-bottom: 10px;
}
/* Titles */
/**********/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Helvetica Neue', 'Tahoma', sans-serif;
  color: #C2101F;
  font-weight: bold;
  /*color: var(--c-text-gradient-article-from);
    background-image: linear-gradient(90deg,var(--c-text-gradient-article-from),var(--c-text-gradient-article-to));*/
}
h1.medium,
.h1.medium,
h2.medium,
.h2.medium,
h3.medium,
.h3.medium,
h4.medium,
.h4.medium,
h5.medium,
.h5.medium,
h6.medium,
.h6.medium {
  font-weight: 500;
}
footer h1,
footer .h1,
footer h2,
footer .h2,
footer h3,
footer .h3,
footer h4,
footer .h4,
footer h5,
footer .h5,
footer h6,
footer .h6 {
  color: #fff;
}
h1,
.h1 {
  font-size: 38px;
  line-height: .95;
  letter-spacing: -0.5px;
  max-width: 75%;
  margin-bottom: 25px;
  margin-right: auto;
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 30px;
    line-height: 1.05;
    letter-spacing: -0.25px;
    max-width: 100%;
  }
}
h2,
.h2 {
  font-size: 28px;
  line-height: 1.1;
  letter-spacing: -0.25px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 20px;
  }
}
h3,
.h3 {
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: -0.25px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  h3,
  .h3 {
    font-size: 16px;
  }
}
/* Menu */
/********/
div#header nav a,
div#header nav input,
div#header nav button {
  font-family: 'Helvetica Neue', 'Tahoma', sans-serif;
  color: #fff;
}
@media (max-width: 767px) {
  div#header nav a,
  div#header nav input,
  div#header nav button {
    font-size: 20px;
    line-height: 1.2;
    text-align: right;
  }
}
div#header nav a {
  font-weight: bold;
}
@media (min-width: 768px) {
  div#header nav {
    margin: 0 -10px;
  }
  div#header nav li {
    padding: 0 10px;
  }
  div#header nav li a {
    font-size: 16px;
  }
}
footer li a {
  display: inline-block;
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 5px;
}
/* General */
/***********/
p {
  line-height: 1.35;
}
p.text-large {
  font-style: 'Helvetica Neue', 'Tahoma', sans-serif;
  font-size: 22px;
  line-height: 1.15;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.65);
  max-width: 600px;
  margin-right: auto;
}
@media (max-width: 767px) {
  p.text-large {
    max-width: 100%;
  }
}
.meta,
figcaption,
.link-edit {
  font-family: 'Helvetica Neue', 'Tahoma', sans-serif;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
}
figcaption {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
mark {
  color: white;
  background-color: #eb5c58;
  padding: 0 2px;
  border-radius: 2px;
}
/* Links */
/*********/
p a {
  color: #C2101F;
}
a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
a.nolink {
  cursor: default;
}
/* Copy */
/***********/
ol {
  padding: 20px;
  list-style-type: decimal;
}
ol li {
  margin: 0 10px;
  padding: 0 10px;
}
/* Lists */
/*********/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
ul li {
  padding: 0;
  margin: 0;
}
/* Change List Bullet Icon*/
main ul:not(.custom-styled) {
  list-style-type: none!important;
  text-indent: -20px;
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
}
main ul:not(.custom-styled) > li {
  margin-bottom: 2px;
}
main ul:not(.custom-styled) > li::before {
  content: "\2014\a0\a0";
  color: #C2101F;
}
main ul:not(.custom-styled) > li li {
  margin-top: 5px;
  margin-bottom: 0;
}
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0 0 25px 0;
  padding: 0;
}
ol li {
  display: table;
  counter-increment: item;
  margin-bottom: 5px;
}
ol li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 10px;
}
/***************************************
*
* Globals
*
***************************************/
*,
::after,
::before {
  box-sizing: inherit;
}
html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
}
html {
  box-sizing: border-box;
  font-size: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  quotes: "\00AB" "\00BB" "\2039" "\203A";
  quotes: "«" "»" "‹" "›";
}
html.smooth-scroll {
  scroll-behavior: smooth;
}
html.has-overlay {
  overflow-y: hidden;
}
html body {
  /*-webkit-transform:translate3d(0,0,0); verträgt sich nicht mit position:fixed; */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "kern" 1;
  -ms-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-variant-ligatures: none;
  color: #000;
}
html body.has-overlay {
  height: 100vh;
  overflow: hidden;
}
::selection {
  color: #fff;
  background-color: rgba(194, 16, 31, 0.85);
  text-shadow: 0;
}
::-moz-selection {
  color: #fff;
  background-color: rgba(194, 16, 31, 0.85);
  text-shadow: none;
}
/***************************************
* Z-Index
***************************************/
/* 1. Ebene */
.toggle-menu,
.logo {
  z-index: 999999999;
}
/* 2. Ebene */
#nav {
  z-index: 99999999;
}
/* 3. Ebene */
div#header,
footer {
  z-index: 9999999;
}
/***************************************
* Elements
***************************************/
div#header,
footer {
  background-color: #292936;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 767px) {
  div#header,
  footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  div#header nav,
  footer nav {
    margin: 0 -20px;
  }
}
footer a,
footer p,
footer span {
  color: #fff;
}
footer hr {
  margin-top: 25px;
  margin-bottom: 50px;
  border-color: #fff;
}
div#header > div,
footer > div,
.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
div#header > div.full-width,
footer > div.full-width,
.container.full-width {
  max-width: 100vw!important;
}
div#header > div.full-width > div,
footer > div.full-width > div,
.container.full-width > div,
div#header > div.full-width > p,
footer > div.full-width > p,
.container.full-width > p {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.banner {
  height: calc( 100vh - 110px );
  background-size: cover;
}
.banner h1,
.banner .h1,
.banner h2,
.banner .h2,
.banner h3,
.banner .h3 {
  max-width: 100%;
}
.bg {
  color: #fff;
  background-color: #eb5c58;
  padding: 8px 20px 12px;
  margin-bottom: 10px;
}
div#title {
  padding-top: 75px;
  padding-bottom: 25px;
  margin-bottom: 75px;
}
section#content {
  padding-top: 110px;
  padding-bottom: 50px;
  background-color: #fff;
}
@media (max-width: 767px) {
  section#content {
    padding-top: 80px;
  }
}
section#content .container {
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 767px) {
  section#content .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
section#content article {
  margin-bottom: 50px;
}
iframe {
  margin-top: 50px;
  margin-bottom: 50px;
}
div.bg-red h1,
span.bg-red h1,
section.bg-red h1,
article.bg-red h1,
div.bg-coral h1,
span.bg-coral h1,
section.bg-coral h1,
article.bg-coral h1,
div.bg-blue h1,
span.bg-blue h1,
section.bg-blue h1,
article.bg-blue h1,
div.bg-lightblue h1,
span.bg-lightblue h1,
section.bg-lightblue h1,
article.bg-lightblue h1,
div.bg-darkgrey h1,
span.bg-darkgrey h1,
section.bg-darkgrey h1,
article.bg-darkgrey h1,
div.bg-black h1,
span.bg-black h1,
section.bg-black h1,
article.bg-black h1,
div.bg-red h2,
span.bg-red h2,
section.bg-red h2,
article.bg-red h2,
div.bg-coral h2,
span.bg-coral h2,
section.bg-coral h2,
article.bg-coral h2,
div.bg-blue h2,
span.bg-blue h2,
section.bg-blue h2,
article.bg-blue h2,
div.bg-lightblue h2,
span.bg-lightblue h2,
section.bg-lightblue h2,
article.bg-lightblue h2,
div.bg-darkgrey h2,
span.bg-darkgrey h2,
section.bg-darkgrey h2,
article.bg-darkgrey h2,
div.bg-black h2,
span.bg-black h2,
section.bg-black h2,
article.bg-black h2,
div.bg-red h3,
span.bg-red h3,
section.bg-red h3,
article.bg-red h3,
div.bg-coral h3,
span.bg-coral h3,
section.bg-coral h3,
article.bg-coral h3,
div.bg-blue h3,
span.bg-blue h3,
section.bg-blue h3,
article.bg-blue h3,
div.bg-lightblue h3,
span.bg-lightblue h3,
section.bg-lightblue h3,
article.bg-lightblue h3,
div.bg-darkgrey h3,
span.bg-darkgrey h3,
section.bg-darkgrey h3,
article.bg-darkgrey h3,
div.bg-black h3,
span.bg-black h3,
section.bg-black h3,
article.bg-black h3,
div.bg-red h4,
span.bg-red h4,
section.bg-red h4,
article.bg-red h4,
div.bg-coral h4,
span.bg-coral h4,
section.bg-coral h4,
article.bg-coral h4,
div.bg-blue h4,
span.bg-blue h4,
section.bg-blue h4,
article.bg-blue h4,
div.bg-lightblue h4,
span.bg-lightblue h4,
section.bg-lightblue h4,
article.bg-lightblue h4,
div.bg-darkgrey h4,
span.bg-darkgrey h4,
section.bg-darkgrey h4,
article.bg-darkgrey h4,
div.bg-black h4,
span.bg-black h4,
section.bg-black h4,
article.bg-black h4,
div.bg-red h5,
span.bg-red h5,
section.bg-red h5,
article.bg-red h5,
div.bg-coral h5,
span.bg-coral h5,
section.bg-coral h5,
article.bg-coral h5,
div.bg-blue h5,
span.bg-blue h5,
section.bg-blue h5,
article.bg-blue h5,
div.bg-lightblue h5,
span.bg-lightblue h5,
section.bg-lightblue h5,
article.bg-lightblue h5,
div.bg-darkgrey h5,
span.bg-darkgrey h5,
section.bg-darkgrey h5,
article.bg-darkgrey h5,
div.bg-black h5,
span.bg-black h5,
section.bg-black h5,
article.bg-black h5,
div.bg-red h6,
span.bg-red h6,
section.bg-red h6,
article.bg-red h6,
div.bg-coral h6,
span.bg-coral h6,
section.bg-coral h6,
article.bg-coral h6,
div.bg-blue h6,
span.bg-blue h6,
section.bg-blue h6,
article.bg-blue h6,
div.bg-lightblue h6,
span.bg-lightblue h6,
section.bg-lightblue h6,
article.bg-lightblue h6,
div.bg-darkgrey h6,
span.bg-darkgrey h6,
section.bg-darkgrey h6,
article.bg-darkgrey h6,
div.bg-black h6,
span.bg-black h6,
section.bg-black h6,
article.bg-black h6,
div.bg-red .h1,
span.bg-red .h1,
section.bg-red .h1,
article.bg-red .h1,
div.bg-coral .h1,
span.bg-coral .h1,
section.bg-coral .h1,
article.bg-coral .h1,
div.bg-blue .h1,
span.bg-blue .h1,
section.bg-blue .h1,
article.bg-blue .h1,
div.bg-lightblue .h1,
span.bg-lightblue .h1,
section.bg-lightblue .h1,
article.bg-lightblue .h1,
div.bg-darkgrey .h1,
span.bg-darkgrey .h1,
section.bg-darkgrey .h1,
article.bg-darkgrey .h1,
div.bg-black .h1,
span.bg-black .h1,
section.bg-black .h1,
article.bg-black .h1,
div.bg-red .h2,
span.bg-red .h2,
section.bg-red .h2,
article.bg-red .h2,
div.bg-coral .h2,
span.bg-coral .h2,
section.bg-coral .h2,
article.bg-coral .h2,
div.bg-blue .h2,
span.bg-blue .h2,
section.bg-blue .h2,
article.bg-blue .h2,
div.bg-lightblue .h2,
span.bg-lightblue .h2,
section.bg-lightblue .h2,
article.bg-lightblue .h2,
div.bg-darkgrey .h2,
span.bg-darkgrey .h2,
section.bg-darkgrey .h2,
article.bg-darkgrey .h2,
div.bg-black .h2,
span.bg-black .h2,
section.bg-black .h2,
article.bg-black .h2,
div.bg-red .h3,
span.bg-red .h3,
section.bg-red .h3,
article.bg-red .h3,
div.bg-coral .h3,
span.bg-coral .h3,
section.bg-coral .h3,
article.bg-coral .h3,
div.bg-blue .h3,
span.bg-blue .h3,
section.bg-blue .h3,
article.bg-blue .h3,
div.bg-lightblue .h3,
span.bg-lightblue .h3,
section.bg-lightblue .h3,
article.bg-lightblue .h3,
div.bg-darkgrey .h3,
span.bg-darkgrey .h3,
section.bg-darkgrey .h3,
article.bg-darkgrey .h3,
div.bg-black .h3,
span.bg-black .h3,
section.bg-black .h3,
article.bg-black .h3,
div.bg-red .h4,
span.bg-red .h4,
section.bg-red .h4,
article.bg-red .h4,
div.bg-coral .h4,
span.bg-coral .h4,
section.bg-coral .h4,
article.bg-coral .h4,
div.bg-blue .h4,
span.bg-blue .h4,
section.bg-blue .h4,
article.bg-blue .h4,
div.bg-lightblue .h4,
span.bg-lightblue .h4,
section.bg-lightblue .h4,
article.bg-lightblue .h4,
div.bg-darkgrey .h4,
span.bg-darkgrey .h4,
section.bg-darkgrey .h4,
article.bg-darkgrey .h4,
div.bg-black .h4,
span.bg-black .h4,
section.bg-black .h4,
article.bg-black .h4,
div.bg-red .h5,
span.bg-red .h5,
section.bg-red .h5,
article.bg-red .h5,
div.bg-coral .h5,
span.bg-coral .h5,
section.bg-coral .h5,
article.bg-coral .h5,
div.bg-blue .h5,
span.bg-blue .h5,
section.bg-blue .h5,
article.bg-blue .h5,
div.bg-lightblue .h5,
span.bg-lightblue .h5,
section.bg-lightblue .h5,
article.bg-lightblue .h5,
div.bg-darkgrey .h5,
span.bg-darkgrey .h5,
section.bg-darkgrey .h5,
article.bg-darkgrey .h5,
div.bg-black .h5,
span.bg-black .h5,
section.bg-black .h5,
article.bg-black .h5,
div.bg-red .h6,
span.bg-red .h6,
section.bg-red .h6,
article.bg-red .h6,
div.bg-coral .h6,
span.bg-coral .h6,
section.bg-coral .h6,
article.bg-coral .h6,
div.bg-blue .h6,
span.bg-blue .h6,
section.bg-blue .h6,
article.bg-blue .h6,
div.bg-lightblue .h6,
span.bg-lightblue .h6,
section.bg-lightblue .h6,
article.bg-lightblue .h6,
div.bg-darkgrey .h6,
span.bg-darkgrey .h6,
section.bg-darkgrey .h6,
article.bg-darkgrey .h6,
div.bg-black .h6,
span.bg-black .h6,
section.bg-black .h6,
article.bg-black .h6,
div.bg-red span,
span.bg-red span,
section.bg-red span,
article.bg-red span,
div.bg-coral span,
span.bg-coral span,
section.bg-coral span,
article.bg-coral span,
div.bg-blue span,
span.bg-blue span,
section.bg-blue span,
article.bg-blue span,
div.bg-lightblue span,
span.bg-lightblue span,
section.bg-lightblue span,
article.bg-lightblue span,
div.bg-darkgrey span,
span.bg-darkgrey span,
section.bg-darkgrey span,
article.bg-darkgrey span,
div.bg-black span,
span.bg-black span,
section.bg-black span,
article.bg-black span,
div.bg-red p,
span.bg-red p,
section.bg-red p,
article.bg-red p,
div.bg-coral p,
span.bg-coral p,
section.bg-coral p,
article.bg-coral p,
div.bg-blue p,
span.bg-blue p,
section.bg-blue p,
article.bg-blue p,
div.bg-lightblue p,
span.bg-lightblue p,
section.bg-lightblue p,
article.bg-lightblue p,
div.bg-darkgrey p,
span.bg-darkgrey p,
section.bg-darkgrey p,
article.bg-darkgrey p,
div.bg-black p,
span.bg-black p,
section.bg-black p,
article.bg-black p,
div.bg-red a,
span.bg-red a,
section.bg-red a,
article.bg-red a,
div.bg-coral a,
span.bg-coral a,
section.bg-coral a,
article.bg-coral a,
div.bg-blue a,
span.bg-blue a,
section.bg-blue a,
article.bg-blue a,
div.bg-lightblue a,
span.bg-lightblue a,
section.bg-lightblue a,
article.bg-lightblue a,
div.bg-darkgrey a,
span.bg-darkgrey a,
section.bg-darkgrey a,
article.bg-darkgrey a,
div.bg-black a,
span.bg-black a,
section.bg-black a,
article.bg-black a {
  color: #fff;
}
div.bg-red,
span.bg-red,
section.bg-red,
article.bg-red {
  background-color: #C2101F;
}
div.bg-coral,
span.bg-coral,
section.bg-coral,
article.bg-coral {
  background-color: #eb5c58;
}
div.bg-darkgrey,
span.bg-darkgrey,
section.bg-darkgrey,
article.bg-darkgrey {
  background-color: #292936;
}
/* Tailwind Customization */
.container {
  margin-right: auto;
  margin-left: auto;
}
.container > div {
  margin: 0 10px;
}
.container.spacing-low {
  padding: 0 -5px;
}
.container.spacing-low > div {
  margin: 0 5px;
}
/* Images */
img {
  display: inline-block;
  border-style: none;
  height: auto;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  font-size: 12.75px;
  text-align: center;
  color: rgba(10, 10, 10, 0.3);
}
img.empty {
  width: 100%;
  padding-bottom: 30%;
}
/* Spacing */
/***********/
header > .wrapper,
main > .wrapper,
footer > .wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
main,
.padding {
  padding: 0 30px;
}
@media (max-width: 767px) {
  main,
  .padding {
    padding: 0 20px;
  }
}
header,
footer {
  padding: 20px 30px;
}
@media (max-width: 767px) {
  header,
  footer {
    padding: 10px 20px;
  }
}
main {
  /*@margin: @logo-height + (2 * @padding-sm);
    .screen-min-md({ @margin: @logo-height + (2 * @padding); });
    margin-top: @margin;
    min-height: ~"calc( 100vh - (2 * @{margin}) )";*/
  margin-top: 70px;
}
footer {
  margin-top: 105px;
}
/* Section/Article */
/*******************/
section article img {
  margin-top: 50px;
  margin-bottom: 50px;
}
section article .article-grid,
section article hr {
  margin-top: 35px;
  margin-bottom: 35px;
}
section article.article-programm .article-grid,
section article.article-programm hr {
  /*margin-top: 25px;
                margin-bottom: 25px;*/
}
section article.article-programm .article-grid .col-text,
section article.article-programm .article-grid .col-time {
  opacity: 0;
  animation: 0.5s ease-in-out 0s 1 appearFromBottom forwards;
}
section article.article-programm .article-grid:nth-of-type(2) .col-text,
section article.article-programm .article-grid:nth-of-type(2) .col-time {
  animation-delay: .15s;
}
section article.article-programm .article-grid:nth-of-type(3) .col-text,
section article.article-programm .article-grid:nth-of-type(3) .col-time {
  animation-delay: .3s;
}
section article.article-programm .article-grid:nth-of-type(4) .col-text,
section article.article-programm .article-grid:nth-of-type(4) .col-time {
  animation-delay: .45s;
}
section article.article-programm .article-grid:nth-of-type(5) .col-text,
section article.article-programm .article-grid:nth-of-type(5) .col-time {
  animation-delay: .6s;
}
section article.article-programm .article-grid:nth-of-type(6) .col-text,
section article.article-programm .article-grid:nth-of-type(6) .col-time {
  animation-delay: .75s;
}
section article.article-programm .article-grid:nth-of-type(7) .col-text,
section article.article-programm .article-grid:nth-of-type(7) .col-time {
  animation-delay: .8s;
}
section article.article-programm .article-grid:nth-of-type(8) .col-text,
section article.article-programm .article-grid:nth-of-type(8) .col-time {
  animation-delay: .95s;
}
section article.article-programm .article-grid:nth-of-type(9) .col-text,
section article.article-programm .article-grid:nth-of-type(9) .col-time {
  animation-delay: 1.1s;
}
section article.article-programm .article-grid:nth-of-type(10) .col-text,
section article.article-programm .article-grid:nth-of-type(10) .col-time {
  animation-delay: 1.25s;
}
section article.article-programm .article-grid .col-time {
  position: relative;
}
section article.article-programm .article-grid .col-time::before {
  content: '\2b24';
  color: #eb5c58;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  text-align: center;
  margin-left: 90px;
}
section article.article-programm .article-grid .col-time::after {
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  height: calc(100% + 45px);
  width: 2px;
  background-color: #eb5c58;
  margin-left: 99px;
}
@media (max-width: 767px) {
  section article.article-programm .article-grid .col-time::before {
    margin-left: 50px;
  }
  section article.article-programm .article-grid .col-time::after {
    margin-left: 59px;
  }
}
section article.article-programm .article-grid:last-of-type .col-time::after {
  content: none;
}
.ani-appear {
  opacity: 0;
  animation: 0.5s ease-in-out 0s 1 appearFromBottom forwards;
}
.ani-appear:nth-of-type(2) {
  animation-delay: .15s;
}
.ani-appear:nth-of-type(3) {
  animation-delay: .3s;
}
.ani-appear:nth-of-type(4) {
  animation-delay: .45s;
}
.ani-appear:nth-of-type(5) {
  animation-delay: .6s;
}
.ani-appear:nth-of-type(6) {
  animation-delay: .75s;
}
.ani-appear:nth-of-type(7) {
  animation-delay: .8s;
}
.ani-appear:nth-of-type(8) {
  animation-delay: .95s;
}
.ani-appear:nth-of-type(9) {
  animation-delay: 1.1s;
}
.ani-appear:nth-of-type(10) {
  animation-delay: 1.25s;
}
@keyframes appearFromBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Default grid, as used by the Article field type */
.article table {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.article-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 0rem;
}
.article-grid + .article-grid {
  margin-top: 1rem;
}
.article-grid figure,
.article-grid img {
  margin: 0;
  width: 100%;
  max-width: 100%;
}
.article-grid .col-1 {
  grid-column: span 1;
}
.article-grid .col-2 {
  grid-column: span 2;
}
.article-grid .col-3 {
  grid-column: span 3;
}
.article-grid .col-4 {
  grid-column: span 4;
}
.article-grid .col-5 {
  grid-column: span 5;
}
.article-grid .col-6 {
  grid-column: span 6;
}
.article-grid .col-7 {
  grid-column: span 7;
}
.article-grid .col-8 {
  grid-column: span 8;
}
.article-grid .col-9 {
  grid-column: span 9;
}
.article-grid .col-10 {
  grid-column: span 10;
}
.article-grid .col-11 {
  grid-column: span 11;
}
.article-grid .col-12 {
  grid-column: span 12;
}
.article-grid div p {
  margin-top: 0;
}
@media (max-width: 479px) {
  .article-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .article-grid .col-1,
  .article-grid .col-2,
  .article-grid .col-3,
  .article-grid .col-4,
  .article-grid .col-5,
  .article-grid .col-6,
  .article-grid .col-7,
  .article-grid .col-8,
  .article-grid .col-9,
  .article-grid .col-10,
  .article-grid .col-11,
  .article-grid .col-12 {
    grid-column: span 1;
    margin-bottom: 1rem;
  }
}
/* Base grid for "Article" powered Fields */
@media (min-width: 640px) {
  .sm\:article > p {
    margin: 1rem 0;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .sm\:article > ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .sm\:article > ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .sm\:article h1 {
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
  .sm\:article h2 {
    font-size: clamp(1.5rem, 2vw, 1.875rem);
  }
  .sm\:article h3 {
    font-size: clamp(1.25rem, 1.75vw, 1.5rem);
    font-weight: 700;
  }
  .sm\:article h4 {
    font-size: clamp(1.125rem, 1.5vw, 1.25rem);
  }
  .sm\:article h5 {
    font-size: 1rem;
    font-weight: 700;
  }
  .sm\:article h6 {
    font-size: 1rem;
    font-weight: 600;
  }
  .sm\:article blockquote > p {
    border-left-width: 4px;
    border-color: #b2f5ea;
    font-style: italic;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
}
@media (min-width: 768px) {
  .md\:article > p {
    margin: 1rem 0;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .md\:article > ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .md\:article > ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .md\:article h1 {
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
  .md\:article h2 {
    font-size: clamp(1.5rem, 2vw, 1.875rem);
  }
  .md\:article h3 {
    font-size: clamp(1.25rem, 1.75vw, 1.5rem);
    font-weight: 700;
  }
  .md\:article h4 {
    font-size: clamp(1.125rem, 1.5vw, 1.25rem);
  }
  .md\:article h5 {
    font-size: 1rem;
    font-weight: 700;
  }
  .md\:article h6 {
    font-size: 1rem;
    font-weight: 600;
  }
  .md\:article blockquote > p {
    border-left-width: 4px;
    border-color: #b2f5ea;
    font-style: italic;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
}
@media (min-width: 1024px) {
  .lg\:article > p {
    margin: 1rem 0;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .lg\:article > ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .lg\:article > ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .lg\:article h1 {
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
  .lg\:article h2 {
    font-size: clamp(1.5rem, 2vw, 1.875rem);
  }
  .lg\:article h3 {
    font-size: clamp(1.25rem, 1.75vw, 1.5rem);
    font-weight: 700;
  }
  .lg\:article h4 {
    font-size: clamp(1.125rem, 1.5vw, 1.25rem);
  }
  .lg\:article h5 {
    font-size: 1rem;
    font-weight: 700;
  }
  .lg\:article h6 {
    font-size: 1rem;
    font-weight: 600;
  }
  .lg\:article blockquote > p {
    border-left-width: 4px;
    border-color: #b2f5ea;
    font-style: italic;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
}
@media (min-width: 1280px) {
  .xl\:article > p {
    margin: 1rem 0;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .xl\:article > ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .xl\:article > ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
  }
  .xl\:article h1 {
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
  .xl\:article h2 {
    font-size: clamp(1.5rem, 2vw, 1.875rem);
  }
  .xl\:article h3 {
    font-size: clamp(1.25rem, 1.75vw, 1.5rem);
    font-weight: 700;
  }
  .xl\:article h4 {
    font-size: clamp(1.125rem, 1.5vw, 1.25rem);
  }
  .xl\:article h5 {
    font-size: 1rem;
    font-weight: 700;
  }
  .xl\:article h6 {
    font-size: 1rem;
    font-weight: 600;
  }
  .xl\:article blockquote > p {
    border-left-width: 4px;
    border-color: #b2f5ea;
    font-style: italic;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: clamp(1.875rem, 2.5vw, 2.25rem);
  }
}
/* Grid */
/********/
.grid.grid-masonry {
  grid-template-rows: masonry;
}
.grid.grid-listing .item.item-bg {
  padding: 10px 20px;
  background-color: #EEEEEE;
}
.grid.grid-listing .item .item-image img {
  width: 100%;
}
.grid[class*="grid-cols-2"] .item {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-color: inherit;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.grid[class*="grid-cols-2"] .item:nth-last-of-type(1) {
  border-bottom: 0;
}
@media (min-width: 768px) {
  .grid[class*="grid-cols-2"] .item:nth-last-of-type(2) {
    border-bottom: 0;
  }
  .grid[class*="grid-cols-2"] .item:nth-child(odd) {
    margin-right: 25px;
  }
  .grid[class*="grid-cols-2"] .item:nth-child(odd)::after {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-color: inherit;
    content: ' ';
    position: absolute;
    right: -25px;
    top: 0;
    height: 100%;
  }
  .grid[class*="grid-cols-2"] .item:nth-child(even) {
    margin-left: 25px;
  }
}
.pagination {
  margin-top: 50px;
}
/* Lists/Entries */
/*****************/
.container-fluid.list-container {
  margin-top: 0;
  margin-bottom: 0;
}
.list-header,
.container-fluid.list-header {
  margin-bottom: 80px;
}
.entry {
  animation: fadeIn 0.5s cubic-bezier(0.5, 0, 0, 0.5) forwards;
  /*.screen-max-sm({
        margin-bottom: 15px;
    });
    .screen-max-xs({
        margin-bottom: 5px;
    });*/
}
.entry > div {
  cursor: pointer;
}
.entry .entry-title {
  margin-top: 5px;
}
.entry .entry-link {
  display: inline-block;
  float: right;
  margin-top: 5px;
}
.entry .entry-image {
  margin-bottom: 8px;
}
/* Pages */
/*********/
.index-map,
.index-map .container,
.index-map .container > div {
  min-height: 500px;
  width: 100vw;
  max-width: 100vw;
  margin-left: 0;
  margin-right: 0;
}
.index-map iframe {
  width: 100vw;
  margin: 0;
}
/* Detail */
/**********/
.artikeldetail {
  max-width: 750px;
  margin: 0 auto;
}
.detail-title {
  margin-bottom: 25px;
}
.detail-teaser {
  margin-bottom: 25px;
}
.detail-image {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .persondetail .detail-image {
    margin-top: 0;
  }
}
/* Personen */
/************/
.home .person-container,
.informationen .person-container {
  text-align: center;
}
.home .entry-person,
.informationen .entry-person {
  display: inline-block;
  width: 250px;
  max-width: 50%;
  vertical-align: top;
  margin-bottom: 35px;
  /*&:nth-of-type(2)::after,
        &:nth-of-type(5)::after{
            content: "\A";
            white-space: pre;
        }*/
}
.home .entry-person .entry-title,
.informationen .entry-person .entry-title,
.home .entry-person .entry-image,
.informationen .entry-person .entry-image {
  margin: 0;
}
.home .entry-person img,
.informationen .entry-person img {
  width: 150px;
  height: 150px;
  border-radius: 9999px;
}
.home .entry-person p,
.informationen .entry-person p {
  margin-bottom: 0;
}
.entry-person-detail {
  border-radius: 9999px !important;
}
/***************************************
* Lazy/Progressive Loading
***************************************/
/* Z-Index */
.progressive-wrapper {
  z-index: 1;
}
.progressive,
.video-container {
  z-index: 10;
}
.fancy-hover.loaded .entry-limit-color:before,
.fancy-hover.fancy-load-independent .entry-limit-color:before,
.fancy-hover.loaded .entry-limit-color:after,
.fancy-hover.fancy-load-independent .entry-limit-color:after {
  z-index: 99;
}
.entry .progressive:before,
.entry .progressive:after {
  z-index: 100;
}
/* Height */
.progressive .lazy-aspect-ratio,
.video-container.enforce-aspect-ratio .lazy-aspect-ratio {
  padding-bottom: 100%!important;
}
.list-similar .progressive .lazy-aspect-ratio,
.list-similar .video-container.enforce-aspect-ratio .lazy-aspect-ratio {
  padding-bottom: 70%!important;
}
/* Actual Progressive */
.progressive {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
}
.progressive:not(.transparent) {
  background-color: #000;
}
.progressive img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: none;
  border: 0 none;
  background-color: transparent;
}
.progressive .aspect-ratio,
.progressive .lazy-aspect-ratio {
  padding-bottom: 75%;
  z-index: -999999;
}
.progressive.enforce-aspect-ratio img {
  position: absolute;
  top: 0;
  left: 0;
}
.progressive:not(.enforce-aspect-ratio) img {
  position: relative;
}
.progressive:not(.enforce-aspect-ratio) img.reveal {
  position: absolute;
}
.progressive:not(.enforce-aspect-ratio) .lazy-aspect-ratio {
  display: none;
  padding: 0;
}
.entry .entry-limit-color {
  position: relative;
}
.entry .entry-limit-color a {
  position: relative;
  display: block;
}
.entry .progressive:before,
.entry .progressive:after {
  content: ' ';
  position: absolute;
  transition: all 1s ease-in-out;
}
.entry .progressive:after {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.25s ease-in-out;
}
.entry .progressive:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.entry .progressive.loaded:before,
.entry .progressive.loaded:after {
  opacity: 0;
}
/* Fancy Hover */
.fancy-hover.loaded,
.fancy-hover.fancy-load-independent {
  /*************************/
  /* NACH UNTEN VERSCHOBEN */
  /*************************/
  /*.screen-min-sm({
        &.entry-limit, .entry-limit{
            &:hover .entry-limit-color{
                //transform: scale(1.02);
                //.progressive{ background-color: @color-red; }
                //&:before, &:after{ opacity: 1; }
                &:after{
                    //opacity: 1;
                    border-width: @width-scrollbar;
                }
            }
        }
    });*/
}
.fancy-hover.loaded .entry-limit-color,
.fancy-hover.fancy-load-independent .entry-limit-color {
  /*.progressive{
            transition: background-color @speed-fast;
        }*/
}
.fancy-hover.loaded .entry-limit-color:before,
.fancy-hover.fancy-load-independent .entry-limit-color:before,
.fancy-hover.loaded .entry-limit-color:after,
.fancy-hover.fancy-load-independent .entry-limit-color:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: transparent;
}
@media (min-width: 768px) {
  .fancy-hover.loaded .entry-limit-color:after,
  .fancy-hover.fancy-load-independent .entry-limit-color:after {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-color: inherit;
    border-width: 0;
    border-color: #C2101F;
    transition: border 0.125s cubic-bezier(0.61, 0.02, 0.42, 0.97);
  }
}
@media (min-width: 768px) {
  .fancy-hover.loaded.entry-limit:hover .entry-limit-color:after,
  .fancy-hover.loaded .entry-limit:hover .entry-limit-color:after,
  .fancy-hover.fancy-load-independent.entry-limit:hover .entry-limit-color:after,
  .fancy-hover.fancy-load-independent .entry-limit:hover .entry-limit-color:after {
    border-width: 5px;
  }
  .entry-limit:hover .fancy-hover.loaded .entry-limit-color:after,
  .entry-limit:hover .fancy-hover.fancy-load-independent .entry-limit-color:after {
    border-width: 5px;
  }
}
hr {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.entry hr {
  width: 50px;
  margin: 0;
}
.search .entry hr {
  margin-bottom: 10px;
}
/***************************************
* Forms
***************************************/
button,
.button,
input,
optgroup,
select,
textarea {
  display: block;
  font-family: 'Helvetica Neue', 'Tahoma', sans-serif;
  font-weight: bold;
  line-height: 1.5;
  outline: 0!important;
  -webkit-appearance: none!important;
  border-radius: 0;
}
section button,
section .button {
  display: inline-block;
  cursor: pointer;
  padding: 8px 18px 9px;
  color: #fff;
  background-color: #C2101F;
  transition: background-color 0.25s ease-in-out;
  border: 0;
  border-radius: 4px;
}
section button[type=submit],
section .button[type=submit] {
  float: right;
}
section button:hover,
section .button:hover {
  cursor: pointer;
  background-color: #eb5c58;
  border-color: #eb5c58;
}
section button.btn-search,
section .button.btn-search,
section button.btn-search.active,
section .button.btn-search.active,
section button.btn-search:active,
section .button.btn-search:active {
  width: 30px;
  padding-top: 8px;
}
section form {
  width: 100%;
  margin-bottom: 25px;
}
section form input::-webkit-input-placeholder,
section form textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
section form input::-moz-placeholder,
section form textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
section form input:-ms-input-placeholder,
section form textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
section form input::placeholder,
section form textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
section form [type=text],
section form [type=password],
section form [type=date],
section form [type=datetime],
section form [type=datetime-local],
section form [type=month],
section form [type=week],
section form [type=email],
section form [type=number],
section form [type=search],
section form [type=tel],
section form [type=time],
section form [type=url],
section form [type=color],
section form textarea,
section form select,
section form input:not([type=radio]):not([type=checkbox]) {
  box-sizing: border-box;
  box-shadow: none;
  height: 50px;
  width: 100%;
  padding: 9px 15px 12px;
  margin-bottom: 15px;
  background-color: #EEEEEE;
}
section form [type=text]:focus,
section form [type=password]:focus,
section form [type=date]:focus,
section form [type=datetime]:focus,
section form [type=datetime-local]:focus,
section form [type=month]:focus,
section form [type=week]:focus,
section form [type=email]:focus,
section form [type=number]:focus,
section form [type=search]:focus,
section form [type=tel]:focus,
section form [type=time]:focus,
section form [type=url]:focus,
section form [type=color]:focus,
section form textarea:focus,
section form select:focus,
section form input:not([type=radio]):not([type=checkbox]):focus {
  outline: 0;
  color: #C2101F;
  background-color: #fff;
  border-color: #C2101F;
}
section form [type=text].error,
section form [type=password].error,
section form [type=date].error,
section form [type=datetime].error,
section form [type=datetime-local].error,
section form [type=month].error,
section form [type=week].error,
section form [type=email].error,
section form [type=number].error,
section form [type=search].error,
section form [type=tel].error,
section form [type=time].error,
section form [type=url].error,
section form [type=color].error,
section form textarea.error,
section form select.error,
section form input:not([type=radio]):not([type=checkbox]).error {
  color: #C2101F;
  border-width: 1px;
  border-color: #fff;
  border-style: dashed;
}
@media (max-width: 767px) {
  section form [type=text],
  section form [type=password],
  section form [type=date],
  section form [type=datetime],
  section form [type=datetime-local],
  section form [type=month],
  section form [type=week],
  section form [type=email],
  section form [type=number],
  section form [type=search],
  section form [type=tel],
  section form [type=time],
  section form [type=url],
  section form [type=color],
  section form textarea,
  section form select,
  section form input:not([type=radio]):not([type=checkbox]) {
    margin-bottom: 15px;
  }
}
section form [type=radio] {
  -webkit-appearance: radio!important;
}
section form [type=radio],
section form [type=radio] + label {
  display: inline-block;
}
section form label {
  display: block;
  margin-bottom: 5px;
}
section form textarea {
  overflow: auto;
  min-height: 200px;
  max-width: 100%;
}
section form textarea[rows] {
  height: auto;
}
section form input:-webkit-autofill,
section form textarea:-webkit-autofill,
section form select:-webkit-autofill,
section form input:-webkit-autofill:hover,
section form textarea:-webkit-autofill:hover,
section form select:-webkit-autofill:hover,
section form input:-webkit-autofill:focus,
section form textarea:-webkit-autofill:focus,
section form select:-webkit-autofill:focus {
  border-color: #ffff99;
  -webkit-text-fill-color: #ffff99;
  -webkit-box-shadow: 0 0 0px 1000px #878787 inset;
  transition: background-color 5000s ease-in-out 0s;
}
section form .form-thanks {
  display: none;
  margin-bottom: 80px;
}
section form .attention {
  position: absolute;
  top: 11px;
  right: 21px;
  pointer-events: none;
  display: none;
}
section form .attention.error {
  display: inline-block;
}
section form .attention path {
  fill: #fff;
}
section form.read-only input,
section form.read-only textarea,
section form.read-only label {
  color: #C2101F !important;
  pointer-events: none;
}
section form.read-only input,
section form.read-only textarea {
  border-color: rgba(194, 16, 31, 0.5) !important;
}
/***************************************
* Icons
***************************************/
.icon-bar {
  display: block;
  height: 2px;
  width: 100%!important;
  position: relative;
  background-color: #000;
  transition: all 0.125s ease-in-out, background-color 0.01s, opacity 0.125s;
  border-radius: 0!important;
}
a:hover .link-back .icon,
.entry-limit a:hover .link-back .icon,
a:hover .link-next .icon,
.entry-limit a:hover .link-next .icon,
a:hover.link-back .icon,
.entry-limit a:hover.link-back .icon,
a:hover.link-next .icon,
.entry-limit a:hover.link-next .icon {
  width: 25px;
}
a.link-back,
a.link-next {
  display: inline-block;
}
a.link-back span,
a.link-next span {
  float: left;
  margin-right: 8px;
}
a.link-back span:last-of-type,
a.link-next span:last-of-type {
  padding-top: 1px;
}
a.link-back .icon,
a.link-next .icon {
  display: block;
  position: relative;
  width: 17px;
  height: 10px;
  transition: width 0.125s ease-in-out;
}
a.link-back .icon .icon-bar,
a.link-next .icon .icon-bar {
  transform-origin: left;
}
a.link-back .icon .icon-bar:nth-of-type(1),
a.link-next .icon .icon-bar:nth-of-type(1) {
  width: 8px!important;
  left: 0px;
  top: 7px;
  transform: rotate(-50deg);
}
a.link-back .icon .icon-bar:nth-of-type(3),
a.link-next .icon .icon-bar:nth-of-type(3) {
  width: 8px!important;
  left: 0px;
  top: 5px;
  transform: rotate(50deg);
}
a.link-back .icon .icon-bar:nth-of-type(2),
a.link-next .icon .icon-bar:nth-of-type(2) {
  width: 100%;
  top: 6px;
}
a.link-next span {
  margin-right: 0;
}
a.link-next .icon .icon-bar {
  float: right;
  transform-origin: right;
}
a.link-next .icon .icon-bar:nth-of-type(1) {
  right: 0px;
}
a.link-next .icon .icon-bar:nth-of-type(3) {
  right: 0px;
}
a.link-external .icon {
  display: inline-block;
  line-height: 10px;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 2px;
  margin-left: 2px;
  transition: width 0.125s ease-in-out, height 0.125s ease-in-out;
  transition-delay: 0.25s;
}
a.link-external .icon .icon-bar {
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: right;
}
a.link-external .icon .icon-bar:nth-of-type(1) {
  width: 1px !important;
  height: 8px;
}
a.link-external .icon .icon-bar:nth-of-type(2) {
  width: 8px!important;
  height: 1px;
}
a.link-external .icon .icon-bar:nth-of-type(3) {
  width: 13px!important;
  transform: rotate(-45deg);
  transition-delay: 0.25s;
}
/***************************************
* SVG
***************************************/
svg .solid,
svg .st0 {
  stroke: none;
}
svg .red {
  fill: #C2101F;
}
svg .line,
svg .dotted {
  fill: none;
  stroke: inherit;
  stroke-width: 1px;
  /* 2px */
  stroke-miterlimit: 0;
  vector-effect: non-scaling-stroke;
}
svg .dotted {
  stroke-linecap: round;
  stroke-dasharray: .25,3.5;
}
svg.inverted path {
  fill: #fff;
}
/***************************************
*
* Menue
*
***************************************/
div#header {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  div#header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
div#header .logo .full svg {
  max-height: 70px;
}
@media (max-width: 767px) {
  div#header .logo .full svg {
    max-height: 60px;
  }
}
div#header .logo .short {
  display: none;
}
div#header #progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 3px;
}
@media (max-width: 767px) {
  div#header button#nav-toggle {
    background-color: #fff;
    margin: -15px -20px;
    height: 90px;
    width: 90px;
  }
  div#header button#nav-toggle svg {
    margin: 15px 30px;
    fill: #000;
  }
}
div#header nav {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media (max-width: 767px) {
  div#header nav {
    margin: 40px -20px 0;
    padding: 10px 20px;
    background-color: #292936;
    /*position: fixed;
    				top: 0; left: 0;
    				width: 100vw;
    				height: 100vh;*/
  }
  div#header nav a {
    display: block;
    font-size: 35px;
    line-height: .9;
    padding: 5px 0 20px 0;
    text-align: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }
  div#header nav a:last-of-type {
    border: none;
  }
}
@media (min-width: 768px) {
  div#header nav {
    position: relative;
    height: 70px;
    overflow-x: hidden;
    margin-left: 25px;
  }
  div#header nav ul {
    float: right;
    white-space: nowrap;
  }
  div#header nav ul li,
  div#header nav ul div {
    display: inline-block;
    vertical-align: middle;
  }
  div#header nav ul li {
    line-height: 70px;
  }
  div#header nav ul li a {
    display: inline-block;
    line-height: 18px;
    word-break: break-word;
    vertical-align: middle;
    text-align: center;
  }
  div#header nav ul li a.active {
    color: #eb5c58;
  }
}
@media (min-width: 768px) {
  div#header.header-up {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  div#header.header-up .logo .short {
    display: block;
  }
  div#header.header-up .logo .full {
    display: none;
  }
  div#header.header-up nav {
    height: 56px;
    margin-top: -15px;
    margin-bottom: -15px;
  }
  div#header.header-up nav li {
    height: 56px;
    line-height: 56px;
  }
  div#header.header-up nav li a {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (min-width: 768px) {
  div#header .search .search-form input,
  div#header .search .search-form button {
    width: 56px;
    height: 56px !important;
  }
  div#header .search .search-form input {
    padding: 5px 0 7px;
    margin-right: -56px;
    opacity: 0;
    transition: width cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, margin-right cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, padding cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s;
  }
  div#header .search .search-form input:focus,
  div#header .search .search-form input:not([value=""]) {
    width: 250px;
    opacity: 1;
    margin-left: 10px;
    margin-right: 0;
    padding: 5px 20px 7px;
    transition: width cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, margin-right cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, padding cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, opacity 0.35s 0.25s;
  }
  div#header .search .search-form input:focus + button,
  div#header .search .search-form input:not([value=""]) + button {
    background-color: #fff;
  }
  div#header .search .search-form input:focus + button svg,
  div#header .search .search-form input:not([value=""]) + button svg {
    fill: black;
  }
  div#header .search .search-form button {
    transition: width cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, padding cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s, background-color cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s;
  }
  div#header .search .search-form button svg {
    transition: fill cubic-bezier(0.61, 0.02, 0.42, 0.97) 0.25s;
  }
}
.search .search-form {
  display: flex;
  overflow: hidden;
}
.search .search-form input,
.search .search-form button {
  flex: initial;
  height: 56px !important;
}
.search .search-form input {
  background-color: #34344c;
}
.search .search-form button {
  background-color: #292936;
  width: 56px;
  border-radius: 0;
}
.search .search-form button svg {
  margin: 0;
}
@media (min-width: 768px) {
  .search {
    margin-left: 0;
  }
  .search .search-form {
    height: 100%;
  }
  .search .search-form input {
    padding: 5px 20px 7px;
  }
  .search .search-form button {
    padding: 0 15px;
  }
  .search .search-form:hover button,
  .search .search-form button:hover {
    background-color: #292936;
  }
  .search .search-form:hover button svg,
  .search .search-form button:hover svg {
    fill: white;
  }
}
@media (max-width: 767px) {
  .search {
    margin-top: 15px;
  }
  .search .search-form input,
  .search .search-form button {
    height: 70px;
  }
  .search .search-form input {
    width: 100%;
    padding: 6px 25px;
    text-align: left;
  }
  .search .search-form button {
    width: 80px;
    padding: 0 20px;
    background-color: #fff;
  }
  .search .search-form button svg {
    fill: black;
    width: 100%;
  }
}
footer .logo.logo-short svg {
  max-height: 26px;
}
@media (max-width: 767px) {
  footer .logo.logo-short svg {
    max-height: 20.5px;
  }
}
/* Menu-Bar Toggle */
/*********/
.toggle-menu,
.toggle-menu:hover {
  position: fixed;
  top: 0;
  right: 0;
  height: 95px;
  width: 100px;
  padding: 30px;
  padding-top: 30px;
  background-color: transparent!important;
  box-shadow: none!important;
  outline: none;
  border: 0;
}
.toggle-menu .icon-bar,
.toggle-menu:hover .icon-bar {
  background-color: #C2101F;
  width: 100%;
  left: 0;
}
.toggle-menu .icon-bar:nth-of-type(1),
.toggle-menu:hover .icon-bar:nth-of-type(1) {
  top: -6px;
}
.toggle-menu .icon-bar:nth-of-type(3),
.toggle-menu:hover .icon-bar:nth-of-type(3) {
  top: 6px;
}
.toggle-menu.toggled,
.toggle-menu:hover.toggled {
  margin-right: 5px;
}
.toggle-menu.toggled .icon-bar,
.toggle-menu:hover.toggled .icon-bar {
  width: 100%!important;
  left: 4px;
}
.toggle-menu.toggled .icon-bar:nth-of-type(1),
.toggle-menu:hover.toggled .icon-bar:nth-of-type(1) {
  top: 2px;
  transform: rotate(45deg);
}
.toggle-menu.toggled .icon-bar:nth-of-type(3),
.toggle-menu:hover.toggled .icon-bar:nth-of-type(3) {
  top: -2px;
  transform: rotate(-45deg);
}
.toggle-menu.toggled .icon-bar:nth-of-type(2),
.toggle-menu:hover.toggled .icon-bar:nth-of-type(2) {
  opacity: 0;
}
@media (min-width: 768px) {
  .toggle-menu,
  .toggle-menu:hover {
    display: none;
  }
}
/***************************************
*
* Utils
*
***************************************/
html.no-transition * {
  transition: none !important;
}
.overlay-only {
  display: none;
}
.has-overlay .overlay-only {
  display: block;
}
/* Margin/Padding */
/******************/
.mt-0-a {
  margin-top: 0!important;
}
.mb-0-a {
  margin-bottom: 0!important;
}
.mx-0-a {
  margin: 0!important;
}
@media screen and (max-width: 479px) {
  .xs-no-margin {
    margin: 0!important;
  }
  .xs-no-margin-bottom {
    margin-bottom: 0!important;
  }
  .hidden-xs {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .padding-top-50 {
    padding-top: 50px!important;
  }
}
@media screen and (min-width: 992px) {
  .padding-right-md-10 {
    padding-right: 10px!important;
  }
  .padding-right-md-25 {
    padding-right: 25px!important;
  }
  .padding-right-md-50 {
    padding-right: 50px!important;
  }
  .padding-right-md-75 {
    padding-right: 75px!important;
  }
}
.no-padding {
  padding: 0!important;
}
.large-margins {
  margin-top: 75px!important;
  margin-bottom: 75px!important;
}
@media (max-width: 991px) {
  .large-margins {
    margin-top: 50px!important;
    margin-bottom: 50px!important;
  }
}
@media (max-width: 767px) {
  .large-margins {
    margin-top: 25px!important;
    margin-bottom: 25px!important;
  }
}
/* Columns */
/***********/
.column-4,
.column-3,
.column-2 {
  column-gap: 30px;
}
.column-4 {
  columns: 4;
}
.column-3 {
  columns: 3;
}
.column-2 {
  columns: 2;
}
@media (max-width: 767px) {
  .column-2,
  .column-3,
  .column-4 {
    columns: 1;
  }
}
@media (max-width: 991px) {
  .column-3,
  .column-4 {
    columns: 2;
  }
}
/*.screen-min-md({
    .spacing-40{
        .column-4, .column-3, .column-2{ column-gap: 40px; } }
    .spacing-60{
        .column-4, .column-3, .column-2{ column-gap: 60px; } }
    .spacing-80{
        .column-4, .column-3, .column-2{ column-gap: 80px; } }
    .spacing-100{
        .column-4, .column-3, .column-2{ column-gap: 100px; }
    }
});
.screen-min-sm({
    .spacing-40{
        .column-4, .column-3, .column-2{ column-gap: 30px; } }
    .spacing-60{
        .column-4, .column-3, .column-2{ column-gap: 40px; } }
    .spacing-80{
        .column-4, .column-3, .column-2{ column-gap: 50px; } }
    .spacing-100{
        .column-4, .column-3, .column-2{ column-gap: 60px; }
    }
});*/
/* Width */
/*********/
.width-33 {
  float: left;
  width: 33%;
}
.width-50 {
  float: left;
  width: 50%;
}
.width-66 {
  float: left;
  width: 66%;
}
/* Full Width */
/**************/
/*.full-width{
    overflow: hidden;
}
.screen-max-sm({
    .full-width{               margin-left:  -@padding-sm!important;
                               margin-right: -@padding-sm!important; }
    .full-width-left{          margin-left:  -@padding-sm!important; }
    .full-width-right{         margin-right: -@padding-sm!important; }
    .full-width-padding-left{  padding-left:  @padding-sm!important; }
    .full-width-padding-right{ padding-right: @padding-sm!important; }
});
.screen-min-md({
    .full-width{               margin-left:  -@padding!important;
                               margin-right: -@padding!important; }
    .full-width-left{          margin-left:  -@padding!important; }
    .full-width-right{         margin-right: -@padding!important; }
    .full-width-padding-left{  padding-left:  @padding!important; }
    .full-width-padding-right{ padding-right: @padding!important; }
});
.screen-min-fullwidth({
    @width-calculated: (@width-max - (2 * @padding)) / 2;
    .full-width{               margin-left:   ~"calc( @{width-calculated} - 50vw)"!important;
                               margin-right:  ~"calc( @{width-calculated} - 50vw)"!important; }
    .full-width-left{          margin-left:   ~"calc( @{width-calculated} - 50vw)"!important; }
    .full-width-right{         margin-right:  ~"calc( @{width-calculated} - 50vw)"!important; }
    .full-width-padding-left{  padding-left:  ~"calc(-@{width-calculated} + 50vw)"!important; }
    .full-width-padding-right{ padding-right: ~"calc(-@{width-calculated} + 50vw)"!important; }
});*/
/* Fixed */
/*********/
.is-static {
  position: static!important;
}
.is-fixed {
  position: fixed!important;
}
.is-absolute {
  position: absolute!important;
}
.is-absolute-bottom {
  position: absolute!important;
  bottom: 0!important;
  top: auto!important;
}
@media screen and (max-width: 479px) {
  .xs-fixed {
    position: fixed;
  }
}
@media screen and (min-width: 480px) {
  .ms-fixed {
    position: fixed;
  }
}
@media screen and (min-width: 768px) {
  .sm-fixed {
    position: fixed;
  }
}
@media screen and (min-width: 992px) {
  .md-fixed {
    position: fixed;
  }
}
@media screen and (min-width: 1200px) {
  .lg-fixed {
    position: fixed;
  }
}
/* Sticky on Scroll */
/********************/
@media (min-width: 768px) {
  .is-sticky {
    display: inline-block;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0 0 10px 0;
    margin-top: -60px;
    padding-top: 60px;
  }
  .height-100pct {
    height: 100%;
  }
}
/* Borders */
/***********/
.no-border {
  border: 0!important;
}
.border {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-color: inherit;
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-color: inherit;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-color: inherit;
}
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-color: inherit;
}
.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-color: inherit;
}
/* Various */
/***********/
.nobreak {
  white-space: nowrap;
}
