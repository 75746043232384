/***************************************
*
* Animations
*
***************************************/


body{
    nav a{
        transition: color @speed-fast @tween-std; }
    .branding .logo svg .solid{
        transition: fill @speed-fast @tween-std;
    }
}


/* Keyframe Animations */
/***********************/

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
.ani-fade-in{
    animation: fadeIn @speed-std @tween-std forwards;
    opacity: 1;
    .ie({ opacity: 1; -ms-animation: none; });
}
.ani-fade-out{
    animation: fadeOut @speed-std @tween-std forwards;
    opacity: 0;
    .ie({ opacity: 1; -ms-animation: none; });
}

/****/

@keyframes shake {
    from, to {
        transform: translate3d(0, 0, 0); }
    10%, 30%, 50%, 70%, 90% {
        transform: translate3d(-2px, 0, 0); }
    20%, 40%, 60%, 80% {
        transform: translate3d(2px, 0, 0);
    }
}
.ani-shake{
    opacity: 1;
    animation: shake .5s ease-in-out forwards;
}


/* Loops */
/*********/

//.loopTransitionDelay(1, 10, 100ms, ~"li");
//.loopTransitionDelay(1, 10, @speed-fast, ~"li");
.loopTransitionDelay (@i, @imax, @moredelay, @selector) when (@i <= @imax){
    @delay: (@i * @moredelay);
    @{selector}:nth-of-type(@{i}){
        transition-delay: @delay;
    }
    .loopTransitionDelay(@i + 1, @imax, @moredelay, @selector);
}
//.loopTransitionDelayReverse(10, 1, 100ms, ~"li");
//.loopTransitionDelayReverse(10, 1, @speed-fast, ~"li");
.loopTransitionDelayReverse (@istart, @itotal, @moredelay, @selector) when (@istart >= 0){
    @iteration: @itotal - @istart;
    @delay: (@iteration * @moredelay);
    @{selector}:nth-of-type(@{istart}){
        transition-delay: @delay;
    }
    .loopTransitionDelayReverse(@istart - 1, @itotal, @moredelay, @selector);
}
//.loopTransitionDelayDelayed(500ms, 1, 10, 100ms, ~"li");
//.loopTransitionDelayDelayed(@speed-slow, 1, 10, @speed-fast, ~"li");
.loopTransitionDelayDelayed (@start, @i, @imax, @moredelay, @selector) when (@i <= @imax){
    @delay: @start + (@i * @moredelay);
    @{selector}:nth-of-type(@{i}){
        transition-delay: @delay;
    }
    .loopTransitionDelayDelayed(@start, @i + 1, @imax, @moredelay, @selector);
}

/****/

//.loopAnimationDelay(1, 10, 100ms, ~"li");
//.loopAnimationDelay(1, 10, @speed-fast, ~"li");
.loopAnimationDelay (@i, @imax, @moredelay, @selector) when (@i <= @imax){
    @delay: (@i * @moredelay);
    @{selector}:nth-of-type(@{i}){
        animation-delay: @delay;
    }
    .loopAnimationDelay(@i + 1, @imax, @moredelay, @selector);
}
//.loopAnimationDelayDelayed(500ms, 1, 10, 100ms, ~"li");
//.loopAnimationDelayDelayed(@speed-slow, 1, 10, @speed-fast, ~"li");
.loopAnimationDelayDelayed (@start, @i, @imax, @moredelay, @selector) when (@i <= @imax){
    @delay: @start + (@i * @moredelay);
    @{selector}:nth-of-type(@{i}){
        animation-delay: @delay;
    }
    .loopAnimationDelayDelayed(@start, @i + 1, @imax, @moredelay, @selector);
}


/* Animation Helpers */
/*********************/

.js-letter-by-letter{
    transition: height .2s ease-in-out;
}

.js-scroll-appear{
  	opacity: 0;
    top: 0;
    transition: opacity .5s ease-in-out, top .75s cubic-bezier(0,.37,.1,.95);

    .in-view{
      	opacity: 1;
        transition-delay: 0.5s;
        .ie({ opacity: 1; -ms-animation: none; });
    }
}
